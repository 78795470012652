import { FC, useState } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import FavoriteIcon from '@mui/icons-material/Favorite'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'

import { styles } from './AudioDetails.style'
import { Container } from '@mui/system'
import { useFavoritesAdd } from '../../api/hooks/useFavoritesAddMutation'
import { useFavoritesRemove } from '../../api/hooks/useFavoritesRemoveMutation'
import { useLogging } from '../../api/hooks/useLoggingMutation'
import { usePlayback } from '../../features/playback/playbackContext'

export interface SentenceDetailsProps {
  variant: 'sentence'
  transcript: string
}

export interface TermDetailsProps {
  variant: 'term'
  title: string
  description: string
  imageUrl?: string
}

export interface AudioDetailsBase {
  Uuid: string
  isFavorite: boolean
  setIsFullscreen: React.Dispatch<React.SetStateAction<boolean>>
  openDetails: (open: boolean) => void
  children?: never
}

export type AudioDetailsProps = AudioDetailsBase &
  (SentenceDetailsProps | TermDetailsProps)

/**
 * AudioDetails displays a persistent drawer with minimal audio track controls.
 * @param props Properties for the component.
 * @returns React component.
 */
export const AudioDetails: FC<AudioDetailsProps> = (
  props: AudioDetailsProps
) => {
  const { variant, isFavorite, Uuid, setIsFullscreen, openDetails } = {
    ...props,
  }
  const playbackContext = usePlayback()
  const [isFavoriteLocal, setIsFavoriteLocal] = useState(isFavorite) // local state for if favorite or not, to avoid reloading everything when favorite status is changed
  const addFavoriteMutation = useFavoritesAdd()
  const removeFavoriteMutation = useFavoritesRemove()
  const logger = useLogging()

  const handlePlayClick = async (): Promise<void> => {
    if (playbackContext != null) {
      const { isPlaying, play, pause } = playbackContext
      if (isPlaying) {
        pause()
        logger.mutate({ activityType: 'PausePlaying', sentenceID: Uuid })
      } else {
        play()
        logger.mutate({ activityType: 'StartPlaying', sentenceID: Uuid })
      }
    }
  }

  const handleExpandClick = (): void => {
    logger.mutate({ activityType: 'ExpandPlayer', sentenceID: Uuid })
    setIsFullscreen(true)
  }

  const handleCloseClick = (): void => {
    openDetails(false)
  }

  const handleFavoriteClick = (): void => {
    if (isFavoriteLocal) {
      openDetails(false)
      removeFavoriteMutation.mutate({
        id: Uuid,
        favoriteType: props.variant,
      })
      logger.mutate({ activityType: 'RemoveFavorite', sentenceID: Uuid })
    } else {
      addFavoriteMutation.mutate({ id: Uuid, favoriteType: props.variant })
      logger.mutate({ activityType: 'AddFavorite', sentenceID: Uuid })
    }
    setIsFavoriteLocal(!isFavoriteLocal)
  }

  const actionIcons = (
    <>
      <IconButton
        sx={[styles.iconButton, styles.favoriteButton]}
        onClick={handleFavoriteClick}
      >
        {isFavoriteLocal ? (
          <FavoriteIcon color="secondary" sx={styles.playIcon} />
        ) : (
          <FavoriteBorderIcon color="secondary" sx={styles.playIcon} />
        )}
      </IconButton>
      {playbackContext != null && (
        <IconButton
          onClick={handlePlayClick}
          sx={[styles.iconButton, styles.playButton]}
        >
          {playbackContext?.isPlaying ?? false ? (
            <PauseCircleOutlineIcon htmlColor="white" sx={styles.playIcon} />
          ) : (
            <PlayCircleOutlineIcon htmlColor="white" sx={styles.playIcon} />
          )}
        </IconButton>
      )}
    </>
  )

  return (
    <Container maxWidth="md">
      <Box
        sx={[
          styles.root,
          variant === 'term' ? styles.termRoot : styles.sentenceRoot, // Vary style based on variant
        ]}
      >
        <Box sx={styles.drawerHeader}>
          <IconButton
            sx={[styles.iconButton, styles.closeIconButton]}
            onClick={handleCloseClick}
          >
            <ExpandMoreIcon sx={styles.closeIcon} />
          </IconButton>
          {variant === 'sentence' ? ( // Only show the expand button if sentence item
            <IconButton
              sx={[styles.iconButton, styles.expandIconButton]}
              onClick={handleExpandClick}
            >
              <OpenInFullIcon />
            </IconButton>
          ) : (
            actionIcons // For term items we show action buttons instead
          )}
        </Box>
        <Box sx={styles.drawerBody}>
          {(() => {
            // Determine drawer body content based on variant
            switch (variant) {
              case 'sentence': {
                return (
                  <Typography sx={styles.transcript} component="p" variant="h4">
                    {(props as SentenceDetailsProps).transcript}
                  </Typography>
                )
              }
              case 'term': {
                const term = props as TermDetailsProps
                return (
                  <Box sx={styles.termBodyContent}>
                    <Typography
                      sx={styles.termTitle}
                      component="p"
                      variant="h3"
                    >
                      {term.title}
                    </Typography>
                    <Box sx={styles.termContent}>
                      <Typography
                        sx={styles.termDescription}
                        component="p"
                        variant="body2"
                      >
                        {term.description}
                      </Typography>
                      {term.imageUrl && (
                        <Box
                          sx={styles.termImage}
                          component="img"
                          src={term.imageUrl}
                        />
                      )}
                    </Box>
                  </Box>
                )
              }
            }
          })()}
          {variant === 'sentence' && actionIcons}
        </Box>
      </Box>
    </Container>
  )
}

export default AudioDetails
