import { UseMutationResult } from 'react-query'
import {
  CreateUserInfo,
  GenericResponse,
  ErrorResponse,
} from '../../openapi/api'
import { useApi } from '../ApiProvider'

export const useCreateUser = (): UseMutationResult<
  GenericResponse,
  ErrorResponse,
  CreateUserInfo
> => {
  const { useCreateUserMutation } = useApi()
  return useCreateUserMutation()
}
