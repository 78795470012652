import { createStyles } from '../../theme'

export const styles = createStyles({
  content: {
    padding: '27px 26px 0px 26px',
  },
  button: {
    width: '100%',
    borderRadius: '10px',
    marginBottom: '20px',
  },
  buttonText: {
    padding: '20px 0 20px 0',
  },
})
