import { UseMutationResult } from 'react-query'
import { useApi } from '../ApiProvider'
import {
  ErrorResponse,
  GenericResponse,
  UpdateRatingRequest,
} from '../../openapi/api/models'

export const useUpdateRating = (): UseMutationResult<
  GenericResponse,
  ErrorResponse,
  UpdateRatingRequest
> => {
  const { useUpdateRatingMutation } = useApi()
  return useUpdateRatingMutation()
}
