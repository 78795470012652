import { IntlProvider } from 'react-intl'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { createTheme, ThemeProvider } from '@mui/material'

import { ApiProvider } from './api/ApiProvider'
import store from './state/store'
import { theme } from './theme'

import messages_da from './translations/da.json'
import messages_en from './translations/en.json'
import AuthAwareRouter from './components/router/AuthAwareRouter'

const messages = {
  da: messages_da,
  en: messages_en,
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

const App = (): JSX.Element => {
  const locale = 'da'
  const lan = messages[locale as keyof object]
  const apiUrl = 'https://test.alexandra.dk/api'

  return (
    <Provider store={store}>
      <IntlProvider locale={locale} key={locale} messages={lan}>
        <ThemeProvider theme={createTheme(theme)}>
          <QueryClientProvider client={queryClient}>
            <ApiProvider apiUrl={apiUrl}>
              <BrowserRouter>
                <AuthAwareRouter />
              </BrowserRouter>
            </ApiProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </IntlProvider>
    </Provider>
  )
}

export default App
