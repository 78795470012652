/* tslint:disable */
/* eslint-disable */
/**
 * Migsosu BFF
 * Backend for frontend API for Migsosu app.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Term
 */
export interface Term {
    /**
     * 
     * @type {string}
     * @memberof Term
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof Term
     */
    subcategory?: string;
    /**
     * 
     * @type {string}
     * @memberof Term
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Term
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof Term
     */
    audioUrl: string | null;
    /**
     * 
     * @type {string}
     * @memberof Term
     */
    imageUrl: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Term
     */
    isFavorite?: boolean;
}

/**
 * Check if a given object implements the Term interface.
 */
export function instanceOfTerm(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uuid" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "audioUrl" in value;
    isInstance = isInstance && "imageUrl" in value;

    return isInstance;
}

export function TermFromJSON(json: any): Term {
    return TermFromJSONTyped(json, false);
}

export function TermFromJSONTyped(json: any, ignoreDiscriminator: boolean): Term {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'subcategory': !exists(json, 'subcategory') ? undefined : json['subcategory'],
        'title': json['title'],
        'description': json['description'],
        'audioUrl': json['audioUrl'],
        'imageUrl': json['imageUrl'],
        'isFavorite': !exists(json, 'isFavorite') ? undefined : json['isFavorite'],
    };
}

export function TermToJSON(value?: Term | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uuid': value.uuid,
        'subcategory': value.subcategory,
        'title': value.title,
        'description': value.description,
        'audioUrl': value.audioUrl,
        'imageUrl': value.imageUrl,
        'isFavorite': value.isFavorite,
    };
}

