import { colors, createStyles } from '../../theme'

export const styles = createStyles({
  root: {
    width: '100%',
  },
  textInput: {
    backgroundColor: colors.white,
    borderRadius: '8px',
    '& .MuiFilledInput-root': {
      borderRadius: '8px',
    },
  },
  searchOptionButton: {
    width: '100%',
    borderRadius: '0',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.white,
    '&:hover': {
      backgroundColor: colors.brandBaseLight,
    },
  },
  searchOptionTitle: {
    textAlign: 'left',
    marginRight: 'auto',
  },
  searchOptionCategoryText: {
    display: 'flex',
    alignItems: 'center',
    color: colors.textHeader,
  },
  searchOptionCategoryContainer: {
    display: 'flex',
    marginRight: 'auto',
  },
  categoryIcon: {
    fontSize: '18px',
  },
})
