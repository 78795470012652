import { colors, createStyles } from '../../theme'

export const styles = createStyles({
  root: {
    marginBottom: '27px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '12px',
  },
  listContainer: {
    marginTop: '20px',
  },
  backIcon: {
    marginLeft: 'auto',
    fontSize: '32px',
  },
  infoIcon: {
    color: colors.textHeader,
    fontSize: '42px',
    position: 'absolute',
    left: 'calc(50% - 92px)',
  },
  emptyListIcon: {
    color: colors.textHeader,
    fontSize: '124px',
    marginX: 'auto',
  },
  infoMessageContainer: {
    position: 'absolute',
    left: '50vw',
    top: '50vh',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
  },
  infoMessage: {
    textAlign: 'center',
  },
})
