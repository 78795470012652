import { colors, createStyles } from '../../theme'

export const styles = createStyles({
  root: {
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    textAlign: 'left',
    padding: '30px',
    boxSizing: 'border-box',
    width: '100%',
    marginBottom: '15px',
  },
  detailsFullscreen: {
    height: 384,
  },
  playIcon: {
    marginLeft: 'auto',
    paddingLeft: '20px',
    fontSize: '38px',
    color: colors.dialog,
  },
  detailsContainer: {
    backgroundColor: colors.dialog,
    borderRadius: '34px 0px 0px 0px',
  },
  errorIcon: {
    marginRight: '10px',
  },
  additionalInfoContainer: {
    display: 'flex',
    marginLeft: '32px',
    height: '100%',
  },
  errorText: {
    color: 'gray',
  },
})
