import { FC, useRef, useState } from 'react'
import {
  Box,
  Button,
  IconButton,
  Popover,
  Slider,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import ReplayIcon from '@mui/icons-material/Replay'
import PauseCircleIcon from '@mui/icons-material/PauseCircle'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { styles } from './AudioDetailsFull.style'
import { Container } from '@mui/system'
import { useLogging } from '../../api/hooks/useLoggingMutation'
import { usePlayback } from '../../features/playback/playbackContext'

export interface AudioDetailsFullProps {
  transcript: string
  setIsFullscreen: React.Dispatch<React.SetStateAction<boolean>>
  children?: never
  sentenceID: string
}

/**
 * AudioDetailsFull displays a drawer with expanded audio track controls.
 * @param props Properties for the component.
 * @returns React component.
 */
export const AudioDetailsFull: FC<AudioDetailsFullProps> = ({
  transcript,
  setIsFullscreen,
  sentenceID,
}: AudioDetailsFullProps) => {
  const playbackContext = usePlayback()
  const [speedDialogOpen, setSpeedDialogOpen] = useState<boolean>(false)
  const [sliderValue, setSliderValue] = useState(0)
  const [manualSliding, setManualSliding] = useState(false)
  const logger = useLogging()

  const handlePlayClick = async (): Promise<void> => {
    if (playbackContext != null) {
      const { isPlaying, play, pause } = playbackContext
      if (isPlaying) {
        pause()
        logger.mutate({ activityType: 'PausePlaying', sentenceID: sentenceID })
      } else {
        play()
        logger.mutate({ activityType: 'StartPlaying', sentenceID: sentenceID })
      }
    }
  }

  const handleSliderCommit = (_: any, value: number | number[]): void => {
    playbackContext?.setPlaybackPosition(value as number)
    logger.mutate({
      activityType: 'UsedPlaybackSlider',
      sentenceID: sentenceID,
    })
    setManualSliding(false)
  }

  const handleSliderChange = (_: any, value: number | number[]): void => {
    setManualSliding(true)
    setSliderValue(value as number)
  }

  const handleResetClick = (_: React.MouseEvent<HTMLElement>): void => {
    playbackContext?.setPlaybackPosition(0)
    logger.mutate({ activityType: 'UsedResetClick', sentenceID: sentenceID })
  }

  const handleSpeedChange = (
    _: React.MouseEvent<HTMLElement>,
    newSpeed: number
  ): void => {
    setSpeedDialogOpen(false)
    playbackContext?.setPlaybackRate(newSpeed)
    logger.mutate({
      activityType: 'ChangedSpeed',
      sentenceID: sentenceID,
      additionalInfo: JSON.stringify({ speed: newSpeed }),
    })
  }

  const handleCloseClick = (): void => {
    setIsFullscreen(false)
  }

  const playbackRate = playbackContext?.playbackRate ?? 1
  const playbackPosition = playbackContext?.playbackPosition ?? 0

  const popoverButton = useRef<HTMLButtonElement>(null)
  return (
    <Container maxWidth="md">
      <Box sx={styles.header}>
        <IconButton sx={styles.closeIconButton} onClick={handleCloseClick}>
          <ExpandMoreIcon sx={styles.closeIcon} />
        </IconButton>
      </Box>
      <Box sx={styles.root}>
        <Typography
          sx={styles.transcript}
          component="p"
          variant="h3"
          color="white"
        >
          {transcript}
        </Typography>
        <Slider
          sx={styles.playbackSlider}
          min={0}
          step={0.01}
          max={playbackContext?.duration}
          onChangeCommitted={handleSliderCommit}
          value={manualSliding ? sliderValue : playbackPosition}
          onChange={handleSliderChange}
        />
        <Box sx={styles.controlsContainer}>
          <Button
            sx={[styles.playbackSpeedButton, styles.controlsButton]}
            variant="text"
            ref={popoverButton}
            onClick={() => setSpeedDialogOpen(true)}
          >
            {`${playbackRate}x`}
          </Button>
          <Popover
            open={speedDialogOpen}
            onClose={() => setSpeedDialogOpen(false)}
            anchorEl={popoverButton.current}
            anchorOrigin={{
              horizontal: 'left',
              vertical: 'center',
            }}
            transformOrigin={{
              horizontal: 'right',
              vertical: 'bottom',
            }}
          >
            <ToggleButtonGroup
              orientation="vertical"
              value={playbackRate}
              exclusive
              onChange={handleSpeedChange}
            >
              <ToggleButton value="0.5">0.5x</ToggleButton>
              <ToggleButton value="0.75">0.75x</ToggleButton>
              <ToggleButton value="1">1x</ToggleButton>
              <ToggleButton value="1.25">1.25x</ToggleButton>
              <ToggleButton value="1.5">1.5x</ToggleButton>
            </ToggleButtonGroup>
          </Popover>
          <IconButton
            sx={[styles.playIcon, styles.controlsButton]}
            onClick={handlePlayClick}
          >
            {playbackContext?.isPlaying ?? false ? (
              <PauseCircleIcon sx={styles.playIcon} />
            ) : (
              <PlayCircleIcon sx={styles.playIcon} />
            )}
          </IconButton>
          <IconButton sx={styles.controlsButton} onClick={handleResetClick}>
            <ReplayIcon sx={styles.replayIcon} />
          </IconButton>
        </Box>
      </Box>
    </Container>
  )
}

export default AudioDetailsFull
