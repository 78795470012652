import { FC } from 'react'

import { Box, Button, Typography } from '@mui/material'
import { styles } from './LayoutLogin.style'
import { useNavigate } from 'react-router-dom'
import { paths } from '../../paths'
import { useIntl } from 'react-intl'

export interface LayoutProps {
  children: JSX.Element[] | JSX.Element
  title: string | undefined
  logout?: () => void
  isLogin: boolean
}

/**
 * Layout creates header and footer for app when not logged in.
 * @param props Properties for the component.
 * @returns React component.
 */
export const LayoutLogin: FC<LayoutProps> = ({
  title,
  children,
  logout,
  isLogin = false,
}: LayoutProps) => {
  const navigate = useNavigate()
  const intl = useIntl()

  const handleHomeClick = (): void => {
    navigate(paths.categories)
  }

  return (
    <Box sx={styles.root}>
      <Box sx={styles.topMenu}>
        <Button variant="text" onClick={handleHomeClick}>
          <Typography variant="h1" sx={styles.title}>
            {intl.formatMessage({ id: title })}
          </Typography>
        </Button>
      </Box>
      <Box sx={isLogin ? styles.contentLogin : styles.content}>
        <Box>{children}</Box>
      </Box>
    </Box>
  )
}

export default LayoutLogin
