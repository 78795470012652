import { FC, useState } from 'react'

import { Box, Button, Drawer, IconButton, Typography } from '@mui/material'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined'
import MenuIcon from '@mui/icons-material/Menu'
import SearchIcon from '@mui/icons-material/Search'

import { styles } from './Layout.style'
import { useNavigate, useParams } from 'react-router-dom'
import { paths, filePaths } from '../../paths'
import { Container } from '@mui/system'
import SearchBar from '../searchbar/SearchBar'
import { FormattedMessage, useIntl } from 'react-intl'

export interface LayoutProps {
  children: JSX.Element[] | JSX.Element
  title: string | undefined
  logout?: () => void
  beforeNavigate?: () => Promise<void> // Way of injecting unity unloading upon navigation
  isBodyAtlas?: boolean
  showLanguageModeTabs?: boolean
  languageMode?: 'terms' | 'sentences'
}

/**
 * Layout creates header and footer for app.
 * @param props Properties for the component.
 * @returns React component.
 */
export const Layout: FC<LayoutProps> = ({
  title,
  children,
  logout,
  beforeNavigate,
  isBodyAtlas,
  showLanguageModeTabs,
  languageMode,
}: LayoutProps) => {
  const intl = useIntl()
  const [showSearch, setShowSearch] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { categoryName, subcategoryName } = useParams()
  const navigate = useNavigate()

  // Somewhat ugly way of handling unity bug which requires unloading unity before navigation.
  const customNavigate =
    beforeNavigate !== undefined
      ? (path: string): void => {
          void beforeNavigate().then(() => navigate(path))
        }
      : (path: string): void => {
          navigate(path)
        }

  const handleFavoriteClick = (): void => {
    customNavigate(paths.favorites)
  }

  const handleMenuClick = (): void => {
    setIsMenuOpen(true)
  }

  const handleLogoutClick = (): void => {
    beforeNavigate?.()
    logout?.()
  }

  const handleProfileClick = (): void => {
    customNavigate(paths.profile)
  }

  const handleHelpClick = (): void => {
    window.open(filePaths.usermanuaul, '_blank')
  }

  const handleHomeClick = (): void => {
    customNavigate(paths.home)
  }

  const handleSearchClick = (): void => {
    setShowSearch(!showSearch)
  }

  // Switch to language help mode
  const handleLanguageHelpClick = () => {
    /**
     * - In category list view
     *  Navigate to "sentences" mode
     */
    if (!categoryName && !subcategoryName) {
      navigate(`${paths.categories}/sentences`)
      return
    }

    /**
     * - In a subcategory list view for a specific category
     * - In a term list view for a specifc subcategory
     * In both cases: Navigate to sentence list view for that category
     */
    if (categoryName) {
      navigate(`${paths.categories}/${categoryName}/sentences`)
      return
    }
  }

  // Switch to terms help mode
  const handleTermsHelpClick = () => {
    /**
     * - In category list view
     * Navigate to "subcatgories" mode
     */
    if (!categoryName && !subcategoryName) {
      navigate(`${paths.categories}/subcategories`)
      return
    }

    /**
     * - In a sentence list view for a specifc category
     * Navigate to subcategory list view for that category
     */
    if (categoryName) {
      navigate(`${paths.categories}/${categoryName}/subcategories`)
      return
    }
  }

  return (
    <>
      <Box sx={styles.root}>
        <Box sx={styles.topMenu}>
          <Button variant="text" onClick={handleHomeClick}>
            <Typography variant="h1" sx={styles.title}>
              {intl.formatMessage({ id: title })}
            </Typography>
          </Button>
          {!(isBodyAtlas ?? false) && (
            <>
              <IconButton
                onClick={handleSearchClick}
                sx={styles.searchIconButton}
              >
                <SearchIcon />
              </IconButton>
              <IconButton
                onClick={handleFavoriteClick}
                sx={styles.favoriteIconButton}
              >
                <FavoriteBorderOutlinedIcon />
              </IconButton>
            </>
          )}
          <IconButton
            onClick={handleMenuClick}
            sx={[
              styles.menuIconButton,
              isBodyAtlas ?? false ? { marginLeft: 'auto' } : {},
            ]}
          >
            <MenuIcon />
          </IconButton>
        </Box>
        {showSearch && (
          <Container maxWidth="md" sx={styles.searchContainer}>
            <SearchBar languageMode={languageMode} />
          </Container>
        )}
        <Box sx={styles.content}>
          {showLanguageModeTabs && (
            <Box sx={styles.languageModeContainer}>
              <Button
                disabled={languageMode === 'terms'}
                onClick={handleTermsHelpClick}
                sx={styles.languageModeButton}
              >
                {intl.formatMessage({ id: 'general.termsHelp' })}
              </Button>
              <Button
                disabled={languageMode === 'sentences'}
                onClick={handleLanguageHelpClick}
                sx={styles.languageModeButton}
              >
                {intl.formatMessage({ id: 'general.languagehelp' })}
              </Button>
            </Box>
          )}
          <Box>{children}</Box>
        </Box>
      </Box>
      <Drawer
        anchor="right"
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        variant="temporary"
      >
        <Box sx={styles.menuContainer}>
          <Button
            sx={styles.menuButton}
            variant="text"
            onClick={handleProfileClick}
          >
            <Typography>
              <FormattedMessage id="general.profile" />
            </Typography>
          </Button>
          <Button
            sx={styles.menuButton}
            variant="text"
            onClick={handleHelpClick}
          >
            <Typography>
              <FormattedMessage id="general.help" />
            </Typography>
          </Button>
          <Button
            sx={styles.menuButton}
            variant="text"
            onClick={handleLogoutClick}
          >
            <Typography>
              <FormattedMessage id="general.logout" />
            </Typography>
          </Button>
        </Box>
      </Drawer>
    </>
  )
}

export default Layout
