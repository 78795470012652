import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Rating,
  TextField,
  Typography,
} from '@mui/material'
import { styles } from './FeedbackDialog.style'
import { Close, Star, StarBorder } from '@mui/icons-material'
import { useIntl } from 'react-intl'
import { useState } from 'react'
import { useShowRating } from '../../api/hooks/useShowRatingQuery'
import { useUpdateRating } from '../../api/hooks/useUpdateRatingMutation'

export const FeedbackDialog = (): JSX.Element => {
  const intl = useIntl()
  const [rating, setRating] = useState<number | null>(null)
  const [comment, setComment] = useState<string>()

  const { data: showDialog } = useShowRating()

  const { mutate: updateRating } = useUpdateRating()

  const handleRatingChange = (
    _: React.SyntheticEvent<Element, Event>,
    value: number | null
  ) => {
    setRating(value)
  }

  const handleDialogCloseClick = () => {
    updateRating({}) // Bump last_shown timestamp and show_count
  }

  const handleRatingSendClick = () => {
    console.log('Sending', rating, comment)
    updateRating({
      score: rating != null ? rating : undefined,
      comment: comment,
    })
  }

  const handleRatingRejectClick = () => {
    updateRating({
      rejected: true,
    })
  }

  return (
    <Dialog open={showDialog ?? false} sx={styles.dialog}>
      <DialogContent sx={styles.dialogContent}>
        <Box sx={styles.headerBox}>
          <Typography sx={styles.dialogText}>
            {intl.formatMessage({ id: 'ratingDialog.title' })}
          </Typography>
          <IconButton sx={styles.closeButton} onClick={handleDialogCloseClick}>
            <Close />
          </IconButton>
        </Box>
        <Rating
          value={rating}
          onChange={handleRatingChange}
          sx={styles.rating}
          icon={<Star sx={styles.ratingIcon} />}
          emptyIcon={<StarBorder sx={styles.ratingIcon} />}
        />
        <TextField
          value={comment}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setComment(event.target.value)
          }}
          sx={
            rating !== null
              ? {}
              : {
                  /* Workaround for "display: 'none'" on textarea results in re-render loop */
                  visibility: 'hidden',
                  '& .MuiOutlinedInput-root': {
                    padding: 0,
                  },
                  '& textarea': {
                    display: 'none',
                    height: '0px',
                    width: '0px',
                  },
                }
          }
          multiline
          fullWidth
          rows={4}
          placeholder={intl.formatMessage({
            id: 'ratingDialog.commentPlaceholder',
          })}
        />
      </DialogContent>
      <DialogActions sx={styles.dialogActions}>
        <Button
          sx={styles.dialogButton}
          disabled={rating === null}
          onClick={handleRatingSendClick}
        >
          {intl.formatMessage({ id: 'ratingDialog.send' })}
        </Button>
        <Button sx={styles.dialogButton} onClick={handleRatingRejectClick}>
          {intl.formatMessage({ id: 'ratingDialog.reject' })}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FeedbackDialog
