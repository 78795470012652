/* tslint:disable */
/* eslint-disable */
/**
 * Migsosu BFF
 * Backend for frontend API for Migsosu app.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateRatingRequest
 */
export interface UpdateRatingRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateRatingRequest
     */
    rejected?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateRatingRequest
     */
    score?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateRatingRequest
     */
    comment?: string;
}

/**
 * Check if a given object implements the UpdateRatingRequest interface.
 */
export function instanceOfUpdateRatingRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateRatingRequestFromJSON(json: any): UpdateRatingRequest {
    return UpdateRatingRequestFromJSONTyped(json, false);
}

export function UpdateRatingRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateRatingRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rejected': !exists(json, 'rejected') ? undefined : json['rejected'],
        'score': !exists(json, 'score') ? undefined : json['score'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
    };
}

export function UpdateRatingRequestToJSON(value?: UpdateRatingRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rejected': value.rejected,
        'score': value.score,
        'comment': value.comment,
    };
}

