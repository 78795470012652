import { Icon, SxProps, Theme } from '@mui/material'
import { FC } from 'react'
import { styles } from './CategoryIcon.style'
import { Category } from '../../openapi/api'

export interface CategoryIconProps {
  category: Category
  sx?: SxProps<Theme>
  height?: number
  width?: number
}

/**
 * CategoryIcon displays a category item which is retrieved from the backend
 * @param props Properties of the component
 * @returns React component
 */
export const CategoryIcon: FC<CategoryIconProps> = ({
  category,
  height = 46,
  width = 39,
  sx,
}: CategoryIconProps) => {
  return (
    <Icon sx={[...(Array.isArray(sx) ? sx : [sx]), styles.icon]}>
      <img src={category.iconSrc} alt="" width={width} height={height} />
    </Icon>
  )
}

export default CategoryIcon
