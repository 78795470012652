import { UseQueryResult } from 'react-query'
import { useApi } from '../ApiProvider'
import { Sentence, ErrorResponse, Term } from '../../openapi/api/models'

// Default argument to hide future use for different favorite lists
export const useFavorites = (
  listName: string = 'main'
): UseQueryResult<{ sentences: Sentence[]; terms: Term[] }, ErrorResponse> => {
  const { useFavoritesQuery } = useApi()
  return useFavoritesQuery(listName)
}
