import { colors, createStyles } from '../../theme'

export const styles = createStyles({
  root: {
    padding: '22px 8px 10px 8px',
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
  },
  termRoot: {
    height: 'fit-content',
  },
  sentenceRoot: {
    height: 164,
  },
  playIcon: {
    fontSize: '32px',
  },
  drawerHeader: {
    display: 'flex',
  },
  drawerBody: {
    paddingX: '10px', // Better alignment with svg icons
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    marginTop: 1,
    marginBottom: 'auto',
  },
  expandIconButton: {
    marginLeft: 'auto',
  },
  transcript: {
    color: colors.white,
    width: '70%',
  },
  iconButton: {
    color: colors.white,
    padding: 0,
  },
  playButton: {
    marginLeft: '10px',
  },
  favoriteButton: {
    marginLeft: 'auto',
  },
  closeIconButton: {
    marginRight: 'auto',
  },
  closeIcon: {
    fontSize: '42px',
  },
  termBodyContent: {
    width: '100%',
    height: '100%',
  },
  termImage: {
    maxHeight: '100%',
    width: '50%',
    objectFit: 'contain',
    objectPosition: 'top right',
    marginLeft: 'auto',
  },
  termContent: {
    display: 'flex',
    minHeight: '100px',
    height: 'fit-content',
  },
  termTitle: {
    color: 'white',
    borderBottom: '1px solid rgba(255, 255, 255, 0.5)',
    paddingBottom: '8px',
    marginBottom: '10px',
  },
  termDescription: {
    color: 'white',
    minWidth: '40%',
    marginRight: 2,
  },
})
