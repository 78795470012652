import { createContext, ReactNode, useContext } from 'react'
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
  useQueryClient,
  QueryClient,
} from 'react-query'

import { DefaultApi } from '../openapi/api'
import {
  Category,
  Sentence,
  CreateUserInfo,
  GenericResponse,
  ErrorResponse,
  UserActivityLog,
  BodyPart,
  SubCategory,
  Term,
  GetShowRating200Response,
  UpdateRatingRequest,
} from '../openapi/api/models'
import { useAppSelector } from '../state/hooks'

// Since we need to know the category to determine URL of term
export interface TermWithCategory extends Term {
  category: string
}

/**
 * ApiType specifies the available type of api calls that can be used from the provider.
 */
export interface ApiType {
  useShowRatingQuery: () => UseQueryResult<boolean, GenericResponse>
  useUpdateRatingMutation: () => UseMutationResult<
    GenericResponse,
    ErrorResponse,
    UpdateRatingRequest
  >

  useCategoriesQuery: () => UseQueryResult<Category[], GenericResponse>
  useCategoryQuery: (
    categoryName: string
  ) => UseQueryResult<Category, GenericResponse>
  useSentenceQuery: (
    categoryName: string
  ) => UseQueryResult<Sentence[], ErrorResponse>
  useAllSentencesQuery: () => UseQueryResult<Sentence[], ErrorResponse>
  useAllTermsQuery: () => UseQueryResult<TermWithCategory[], ErrorResponse>
  useBodyPartsQuery: () => UseQueryResult<BodyPart[], GenericResponse>
  useFavoritesQuery: (
    listName: string
  ) => UseQueryResult<{ sentences: Sentence[]; terms: Term[] }, ErrorResponse>
  useFavoritesAddMutation: () => UseMutationResult<
    GenericResponse,
    ErrorResponse,
    { id: string; favoriteType: 'sentence' | 'term'; listName?: string }
  >
  useFavoritesRemoveMutation: () => UseMutationResult<
    GenericResponse,
    ErrorResponse,
    { id: string; favoriteType: 'sentence' | 'term'; listName?: string }
  >
  useLoggingMutation: () => UseMutationResult<
    GenericResponse,
    ErrorResponse,
    UserActivityLog
  >
  useCreateUserMutation: () => UseMutationResult<
    GenericResponse,
    ErrorResponse,
    CreateUserInfo
  >
  useSubCategoriesQuery: (
    categoryName: string
  ) => UseQueryResult<SubCategory[], ErrorResponse>
  useTermsQuery: (
    categoryName: string,
    subCategoryName: string
  ) => UseQueryResult<Term[], ErrorResponse>
  useSubCategoryQuery: (
    categoryName: string,
    subCategoryName: string
  ) => UseQueryResult<SubCategory, ErrorResponse>
}

/**
 * ApiProviderProps specifies props that can be passed to the provider.
 */
interface ApiProviderProps {
  children?: ReactNode
  apiUrl: string
}

// TODO: Fix types, avoid undefined! initial value?
// https://medium.com/@rivoltafilippo/typing-react-context-to-avoid-an-undefined-default-value-2c7c5a7d5947
// eslint-disable-next-line
const ApiContext = createContext<ApiType>(undefined!)

/**
 * Exposes useApi handle that can be used in components to make call to the api.
 * @returns ApiType object.
 */
export const useApi = (): ApiType => {
  return useContext(ApiContext)
}

/**
 * ApiProvider provides access to various queries and actions on the BFF.
 * @param param0 Children and api url.
 * @returns ApiProvider
 */
export const ApiProvider = ({ children }: ApiProviderProps): JSX.Element => {
  const api: DefaultApi = new DefaultApi()
  const csrfToken: string = useAppSelector(state => state.auth.csrf)
  const userID: string = useAppSelector(state => state.auth.user.username)
  const queryClient: QueryClient = useQueryClient()

  /**
   * Call to determine if rating dialog should be shown
   * @returns true | false
   */
  const getShowRating = async (): Promise<GetShowRating200Response> => {
    return await api.getShowRating()
  }

  /**
   * Call to update the rating state when the user interacts with the rating dialog
   * @param rejected Whether the rating dialog has been rejected and should no longer be shown
   * @param score Score picked in the rating dialog
   * @returns Generic response
   */
  const updateRating = async (
    rejected?: boolean,
    score?: number,
    comment?: string
  ): Promise<GenericResponse> => {
    return await api.updateRating({
      updateRatingRequest: {
        rejected: rejected,
        score: score,
        comment: comment,
      },
      xCSRFToken: csrfToken,
    })
  }

  /**
   * Calls api to get a list of categories from the backend
   * @returns A promise for a list of categories
   */
  const getCategories = async (): Promise<Category[]> => {
    return await api.getCategories()
  }

  /**
   * Calls api to get all body parts in a tree structure
   * @returns A list of BodyPart root nodes
   */
  const getBodyParts = async (): Promise<BodyPart[]> => {
    return await api.getBodyParts()
  }

  /**
   * Calls api to get  a list of sentences for a given category name from the backend
   * @param categoryName The category name for which sentences are returned
   * @returns a promise for a list of sentences
   */
  const getSentences = async (categoryName: string): Promise<Sentence[]> => {
    return await api.getSentences({ categoryName: categoryName })
  }

  /**
   * Calls api to get a list of all sentences
   * @returns a promise for a list of all sentences
   */
  const getAllSentences = async (): Promise<Sentence[]> => {
    const categories = await api.getCategories()
    const getSentences = categories.map(
      async category => await api.getSentences({ categoryName: category.name })
    )
    const sentences = Promise.all(getSentences).then(all =>
      all.reduce((allSentences, currentSentences) =>
        allSentences.concat(currentSentences)
      )
    )
    return await sentences
  }

  const getAllTerms = async (): Promise<TermWithCategory[]> => {
    const categories = await api.getCategories()
    const termsByCategory = categories.map(async category =>
      getAllTermsInCategory(category.name).then(terms => {
        return terms.map(term => ({ ...term, category: category.name })) // Annotate terms with category
      })
    )

    const flattenedTerms = Promise.all(termsByCategory).then(terms =>
      terms.reduce((prevTerms, currentTerms) => prevTerms.concat(currentTerms))
    )
    return await flattenedTerms
  }

  const getAllTermsInCategory = async (
    categoryName: string
  ): Promise<Term[]> => {
    const subcategories = await api.getSubcategories({
      categoryName: categoryName,
    })

    const termsInCategory = subcategories.map(async subcategory => {
      const termsInSubcategory = await api.getTerms({
        categoryName: categoryName,
        subCategoryName: subcategory.name,
      })
      return termsInSubcategory
    }, new Array<Term>())

    const flattenedTerms = Promise.all(termsInCategory).then(terms =>
      terms.length > 0
        ? terms.reduce((prevTerms, currentTerms) =>
            prevTerms.concat(currentTerms)
          )
        : []
    )
    return flattenedTerms
  }

  /**
   * Calls api to create a user with given user information
   * @param userInfo Information for the user
   * @returns An object with a field 'message' with a status message
   */
  const createUser = async (
    userInfo: CreateUserInfo
  ): Promise<GenericResponse> => {
    return await api.createUser({
      createUserInfo: userInfo,
      xCSRFToken: csrfToken,
    })
  }

  /**
   * Calls api to update a favorites list by adding or removing sentence
   * @param userID Email of the user
   * @param listName Name of the favorites list
   * @param sentenceID Uuid of the sentence
   * @param action 'add' or 'remove'. Determines if the sentence is to be added
   * or removed from the list
   * @returns Promise with an object containing a simple status message
   */
  const modifyFavorites = async (
    // userID: string,
    listName: string,
    id: string,
    favoriteType: 'sentence' | 'term',
    action: 'add' | 'remove'
  ): Promise<GenericResponse> => {
    // const requestBodyParams = favoriteType === 'sentence' ?
    return await api.modifyFavoritesList({
      userID: userID,
      listName: listName,
      action: action,
      xCSRFToken: csrfToken,
      updateFavoritesInfo: {
        sentenceID: favoriteType === 'sentence' ? id : undefined,
        termID: favoriteType === 'term' ? id : undefined,
      },
    })
  }

  /**
   * Calls api to log a user activity (automatically gets user id from user session)
   * @param activityType Tag describing the activity type
   * @param categoryName Name of a potientially ascociated category
   * @param sentenceID ID of a potentially ascociated Sentence
   * @param additionalInfo Additional description of the activity if needed
   * @returns Promise containing an object with a simple status message
   */
  const logActivity = async (
    activityType: string,
    categoryName?: string,
    sentenceID?: string,
    additionalInfo?: string
  ): Promise<GenericResponse> => {
    // TODO: Not very clean. Better way to create object with optional fields?
    const userActivityLog: UserActivityLog = { activityType: activityType }
    if (categoryName !== undefined) {
      userActivityLog.categoryName = categoryName
    }
    if (sentenceID !== undefined) {
      userActivityLog.sentenceID = sentenceID
    }
    if (additionalInfo !== undefined) {
      userActivityLog.additionalInfo = additionalInfo
    }
    return await api.logActivity({
      xCSRFToken: csrfToken,
      userActivityLog: userActivityLog,
    })
  }

  /**
   * Calls api to get a list of favorite sentences for a given user and list name
   * @param userID Email of the user
   * @param listName Name of the list
   * @returns A Promise for a list of sentences
   */
  const getFavorites = async (
    userID: string,
    listName: string
  ): Promise<{ sentences: Sentence[]; terms: Term[] }> => {
    return await api.getFavorites({ userID: userID, listName: listName })
  }

  /**
   * React hook to get whether the rating dialog should be shown
   * @returns React UseQueryResult for the show dialog state
   */
  const useShowRatingQuery = (): UseQueryResult<boolean, ErrorResponse> =>
    useQuery(['showRating'], async () => (await getShowRating()).showRating)

  /**
   * React hook to update the rating state
   * @returns Generic React UseMutationResult
   */
  const useUpdateRatingMutation = (): UseMutationResult<
    GenericResponse,
    ErrorResponse,
    UpdateRatingRequest
  > =>
    useMutation(
      async ({ rejected, score, comment }) =>
        await updateRating(rejected, score, comment),

      {
        onSuccess: () => {
          void queryClient.invalidateQueries('showRating')
        },
      }
    )

  /**
   * React hook to get a list of categories from the backend
   * @returns React UseQueryResult for a list of category names
   */
  const useCategoriesQuery = (): UseQueryResult<Category[], ErrorResponse> =>
    useQuery(['categories'], async () => await getCategories())

  /**
   * React hook to get a list of sentences from the backend
   * @param categoryName Name of the category which the returned sentences belong to
   * @returns React UseQueryResult for a lsit of sentences
   */
  const useSentenceQuery = (
    categoryName: string
  ): UseQueryResult<Sentence[], ErrorResponse> =>
    useQuery(
      ['sentences', categoryName],
      async () => await getSentences(categoryName)
    )

  /**
   * React hook to get all body parts
   * @returns A list of body part root nodes
   */
  const useBodyPartsQuery = (): UseQueryResult<BodyPart[], ErrorResponse> =>
    useQuery(['bodyParts'], async () => await getBodyParts())

  /**
   * React hook to get a list of all sentences from the backend
   * TODO: Would probably be better to use existing queries (so the sentences cache is actually utilized)
   * @returns
   */
  const useAllSentencesQuery = (): UseQueryResult<Sentence[], ErrorResponse> =>
    useQuery(['sentences'], async () => await getAllSentences())

  const useAllTermsQuery = (): UseQueryResult<
    TermWithCategory[],
    ErrorResponse
  > => useQuery(['terms'], async () => await getAllTerms())

  /**
   * React hook for creating a new user
   * @returns An object with a field 'message' with a status message
   */
  const useCreateUserMutation = (): UseMutationResult<
    GenericResponse,
    ErrorResponse,
    CreateUserInfo
  > =>
    useMutation(async (userInfo: CreateUserInfo) => await createUser(userInfo))

  /**
   * React hook for adding a sentence to a favorites list
   * @returns An object with a field 'message' with a status message
   */
  const useFavoritesAddMutation = (): UseMutationResult<
    GenericResponse,
    ErrorResponse,
    { id: string; favoriteType: 'sentence' | 'term'; listName?: string }
  > =>
    useMutation(
      async (params: {
        id: string
        favoriteType: 'sentence' | 'term'
        listName?: string
      }) =>
        await modifyFavorites(
          params.listName ?? 'main',
          params.id,
          params.favoriteType,
          'add'
        ),
      {
        onSuccess: () => {
          void queryClient.invalidateQueries('favorites')
        },
      }
    )

  /**
   * React hook for removing a sentence from a favorites list
   * @returns An object with a field 'message' with a status message
   */
  const useFavoritesRemoveMutation = (): UseMutationResult<
    GenericResponse,
    ErrorResponse,
    { id: string; favoriteType: 'sentence' | 'term'; listName?: string }
  > =>
    useMutation(
      async (params: {
        id: string
        favoriteType: 'sentence' | 'term'
        listName?: string
      }) =>
        await modifyFavorites(
          params.listName ?? 'main',
          params.id,
          params.favoriteType,
          'remove'
        ),
      {
        onSuccess: () => {
          void queryClient.invalidateQueries('favorites')
        },
      }
    )

  /**
   * React Mutation hook to log user activity
   * @returns An object with a field 'message' with a status message
   */
  const useLoggingMutation = (): UseMutationResult<
    GenericResponse,
    ErrorResponse,
    UserActivityLog
  > =>
    useMutation(
      async (obj: UserActivityLog) =>
        await logActivity(
          obj.activityType,
          obj.categoryName,
          obj.sentenceID,
          obj.additionalInfo
        )
    )

  /**
   * React hook for getting a list of favorite sentences
   * @param listName Name of the favorites list
   * @returns List of favorite sentences
   */
  const useFavoritesQuery = (
    listName: string
  ): UseQueryResult<{ sentences: Sentence[]; terms: Term[] }, ErrorResponse> =>
    useQuery(
      ['favorites', userID, listName],
      async () => await getFavorites(userID, listName)
    )

  const useSubCategoriesQuery = (
    categoryName: string
  ): UseQueryResult<SubCategory[], ErrorResponse> => {
    return useQuery(
      ['subcategories', categoryName],
      async () => await api.getSubcategories({ categoryName: categoryName })
    )
  }

  // TODO: Fix s.t. this and categories use same cache
  const useCategoryQuery = (
    categoryName: string
  ): UseQueryResult<Category, ErrorResponse> => {
    return useQuery(
      ['category', categoryName],
      async () => await api.getCategory({ categoryName: categoryName })
    )
  }

  const useTermsQuery = (
    categoryName: string,
    subCategoryName: string
  ): UseQueryResult<Term[], ErrorResponse> => {
    return useQuery({
      queryKey: ['terms', subCategoryName],
      queryFn: async () =>
        await api.getTerms({
          categoryName: categoryName,
          subCategoryName: subCategoryName,
        }),
      enabled: subCategoryName !== '',
    })
  }

  const useSubCategoryQuery = (
    categoryName: string,
    subCategoryName: string
  ): UseQueryResult<SubCategory, ErrorResponse> => {
    return useQuery({
      queryKey: ['subcategories', subCategoryName],
      queryFn: async () =>
        await api.getSubcategory({
          categoryName: categoryName,
          subCategoryName: subCategoryName,
        }),
      enabled: subCategoryName !== '',
    })
  }

  return (
    <ApiContext.Provider
      value={{
        useShowRatingQuery,
        useUpdateRatingMutation,
        useCategoriesQuery,
        useSentenceQuery,
        useBodyPartsQuery,
        useAllSentencesQuery,
        useAllTermsQuery,
        useCreateUserMutation,
        useFavoritesQuery,
        useFavoritesAddMutation,
        useFavoritesRemoveMutation,
        useLoggingMutation,
        useSubCategoriesQuery,
        useTermsQuery,
        useCategoryQuery,
        useSubCategoryQuery,
      }}
    >
      {children}
    </ApiContext.Provider>
  )
}
