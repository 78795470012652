import { UseQueryResult } from 'react-query'
import { useApi } from '../ApiProvider'
import { ErrorResponse, SubCategory } from '../../openapi/api/models'

export const useSubCategory = (
  categoryName: string,
  subCategoryName: string
): UseQueryResult<SubCategory, ErrorResponse> => {
  const { useSubCategoryQuery } = useApi()
  return useSubCategoryQuery(categoryName, subCategoryName)
}
