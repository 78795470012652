import { Howl } from 'howler'
import { useCallback, useEffect, useState } from 'react'
import { IPlaybackContext } from '../../features/playback/playbackContext'

export interface Playback {
  playback: IPlaybackContext
  error: boolean
  loading: boolean
}

// Hook which encapsulates a playback context
export const usePlayback = (audioUrl: string): Playback => {
  const [sound, setSound] = useState<Howl>()
  const [soundId, setSoundId] = useState<number>()
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [playbackPosition, setPlaybackPosition] = useState<number>(0)
  const [playbackRate, setPlaybackRate] = useState<number>(1)
  const [isPlaying, setIsPlaying] = useState(false)

  useEffect(() => {
    Howler.autoUnlock = false
    Howler.html5PoolSize = 100
  }, [])

  useEffect(() => {
    if (sound === undefined) {
      const sound = new Howl({
        src: [audioUrl],
        html5: true,
        format: 'mp3',
        pool: 1,
      })

      sound.on('load', () => {
        setSound(sound)
        setLoading(false)
      })

      sound.on('unlock', () => {
        setSound(sound)
        setLoading(false)
      })

      sound.on('loaderror', (id, error) => {
        setError(true)
        setLoading(false)
      })

      sound.on('end', () => {
        setPlaybackPosition(0)
        setIsPlaying(false)
      })

      return () => {
        sound.unload()
      }
    }
  }, [sound, audioUrl])

  // Effect which sets up a timer to monitor the audio element and update the ui based on the playback position
  useEffect(() => {
    const updateRate = 100 // in ms
    let timer: NodeJS.Timer
    if (isPlaying && sound !== undefined) {
      // Timer is only set when the audio is playing
      timer = setInterval(() => {
        if (isPlaying) {
          setPlaybackPosition(sound.seek())
        }
      }, updateRate) // Updates in 100 ms intervals
    }

    return () => {
      clearInterval(timer)
    }
  }, [isPlaying, sound])

  const resetAudio = useCallback(() => {
    if (sound !== undefined) {
      // Stop playing and reset position
      setIsPlaying(false)
      setPlaybackPosition(0)
      sound.stop()

      // Reset rate
      setPlaybackRate(1)
      sound.rate(1)
    }
  }, [sound])

  // Handle unloading of the sound
  useEffect(() => {
    if (sound !== undefined) {
      window.addEventListener('beforeunload', () => sound.unload())
      return () => {
        sound.unload()
      }
    }
  }, [sound])

  const setPlayback = useCallback(
    (position: number) => {
      if (sound !== undefined) {
        sound.seek(position, soundId)
        setPlaybackPosition(position)
      }
    },
    [sound, soundId]
  )

  const play = useCallback(() => {
    if (sound !== undefined) {
      setSoundId(sound.play())
      setIsPlaying(true)
    }
  }, [sound])

  const pause = useCallback(() => {
    if (sound !== undefined) {
      sound.pause()
      setIsPlaying(false)
    }
  }, [sound])

  const reset = useCallback(() => {
    resetAudio()
  }, [resetAudio])

  const setRate = useCallback(
    (rate: number | null) => {
      if (rate != null && sound !== undefined) {
        setPlaybackRate(rate)
        sound.rate(rate)
      }
    },
    [sound]
  )

  const playbackContext = {
    duration: sound?.duration() ?? 0,
    playbackPosition: playbackPosition,
    setPlaybackPosition: setPlayback,
    isPlaying: isPlaying,
    play: play,
    pause: pause,
    reset: reset,
    playbackRate: playbackRate,
    setPlaybackRate: setRate,
  }

  return { playback: playbackContext, error: error, loading: loading }
}
