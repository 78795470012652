import { colors, createStyles, theme } from '../../theme'

import BackgroundSvg from '../../assets/layout_accent.svg'

export const styles = createStyles({
  root: {
    backgroundColor: colors.brandBase,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right top',
    backgroundImage: `url(${BackgroundSvg})`,
  },
  title: {
    color: colors.white,
    fontSize: '32px',
  },
  content: {
    position: 'relative',
    marginTop: '42px',
    backgroundColor: colors.white,
    borderRadius: '34px 0px 0px 0px',
  },
  topMenu: {
    display: 'flex',
  },
  searchBar: {
    backgroundColor: 'white',
    borderRadius: '8px',
    margin: '40px auto 0px auto',
    width: 364,
  },
  searchContainer: {
    width: '100%',
    display: 'flex',
    paddingTop: '12px',
  },
  favoriteIconButton: {
    color: colors.white,
  },
  menuIconButton: {
    color: colors.white,
  },
  searchIconButton: {
    marginLeft: 'auto',
    color: colors.white,
  },
  menuButton: {
    width: '100%',
  },
  menuContainer: {
    paddingTop: '22px',
    height: '100%',
    width: '33vw',
    backgroundColor: 'white',
  },
  languageModeContainer: {
    marginTop: '12px',
    width: '80%',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    transform: 'translate(-50%, 0)',
    top: '-48px',
    left: '50%',
    '& button:first-of-type': {
      borderRadius: '32px 0 0 0',
    },
    '& button:last-of-type': {
      borderRadius: '0 32px 0 0',
    },
    '& button:disabled': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
  },
  languageModeButton: {
    borderRadius: '0',
    height: '36px',
    width: '50%',
  },
})
