import { FC } from 'react'
import {
  Box,
  CircularProgress,
  Drawer,
  IconButton,
  Typography,
} from '@mui/material'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

import { styles } from './BodyPartDrawer.style'
import { useLogging } from '../../api/hooks/useLoggingMutation'
import { BodyPart } from '../../openapi/api'
import { usePlayback } from '../../api/hooks/usePlayback'

export interface BodyPartDrawerProps {
  bodyPart: BodyPart
  isOpen?: boolean
  close?: () => void // Function to manage the open state outside this component
  children?: never
}

/**
 * BodyPartDrawer displays a persistent drawer with minimal audio track controls and description.
 * @param props Properties for the component.
 * @returns React component.
 */
export const BodyPartDrawer: FC<BodyPartDrawerProps> = ({
  bodyPart,
  isOpen,
  close,
}: BodyPartDrawerProps) => {
  const { playback, error, loading } = usePlayback(bodyPart.audioUrl)
  const logger = useLogging()

  const handlePlayClick = async (): Promise<void> => {
    if (playback != null) {
      const { isPlaying, play, pause } = playback
      if (isPlaying) {
        pause()
        logger.mutate({
          activityType: 'PauseBodyPartPlaying',
          additionalInfo: `BodyPartId: ${bodyPart.uuid}`,
        })
      } else {
        play()
        logger.mutate({
          activityType: 'StartBodyPartPlaying',
          additionalInfo: `BodyPartId: ${bodyPart.uuid}`,
        })
      }
    }
  }

  const playButton = (() => {
    if (error) {
      return <ErrorOutlineIcon sx={styles.playIcon} />
    } else if (loading) {
      return (
        <Box>
          <CircularProgress size={32} />
        </Box>
      )
    } else {
      return (
        <IconButton
          onClick={handlePlayClick}
          sx={[styles.iconButton, styles.playButton]}
        >
          {playback?.isPlaying ?? false ? (
            <PauseCircleOutlineIcon htmlColor="white" sx={styles.playIcon} />
          ) : (
            <PlayCircleOutlineIcon htmlColor="white" sx={styles.playIcon} />
          )}
        </IconButton>
      )
    }
  })()

  return (
    <Drawer
      anchor="bottom"
      open={isOpen ?? false}
      variant="persistent"
      sx={styles.root}
    >
      <Box sx={styles.content}>
        <Box sx={styles.drawerHeader}>
          <IconButton
            sx={styles.iconButton}
            onClick={() => {
              playback?.reset()
              close?.()
            }}
          >
            <ExpandMoreIcon sx={styles.closeIcon} />
          </IconButton>
          <Typography sx={styles.title} component="p" variant="h4">
            {bodyPart.title}
          </Typography>
        </Box>
        <Box sx={styles.drawerBody}>
          <Typography sx={styles.description} component="p" variant="body2">
            {bodyPart.description}
          </Typography>
          {playButton}
        </Box>
      </Box>
    </Drawer>
  )
}

export default BodyPartDrawer
