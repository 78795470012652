import { createStyles } from '../../theme'

export const styles = createStyles({
  root: {
    marginBottom: '27px',
  },
  icon: {
    marginRight: '10px',
    fontSize: '62px',
    position: 'relative',
    top: '6px', // TODO: maybe another solution
    fill: 'none',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '12px',
  },
  listContainer: {
    marginTop: '20px',
  },
  backIcon: {
    marginLeft: 'auto',
    fontSize: '32px',
  },
  overline: {
    color: '#9fa6a6',
    marginBottom: 1,
  },
})
