import { FC } from 'react'
import { Box, Button, Container, Typography } from '@mui/material'
import { styles } from './FrontPage.style'
import Layout from '../layout/Layout'
import { Link } from 'react-router-dom'
import { paths } from '../../paths'
import { FormattedMessage } from 'react-intl'

export interface FrontPageProps {
  logout: () => void
}

/**
 * FrontPage provides options to navigate to various parts of the app.
 * @param props Properties for the component.
 * @returns React component.
 */
export const FrontPage: FC<FrontPageProps> = ({ logout }: FrontPageProps) => {
  return (
    <Layout title="general.appname" logout={logout}>
      <Container maxWidth="md" sx={styles.content}>
        <Box sx={styles.content}>
          <Button
            component={Link}
            to={`${paths.categories}/sentences`}
            sx={styles.button}
          >
            <Typography variant="h2" sx={styles.buttonText}>
              <FormattedMessage id="general.languagehelp" />
            </Typography>
          </Button>
          <Button
            component={Link}
            to={`${paths.categories}/subcategories`}
            sx={styles.button}
          >
            <Typography variant="h2" sx={styles.buttonText}>
              <FormattedMessage id="general.termsHelp" />
            </Typography>
          </Button>
          <Button component={Link} to={paths.bodyAtlas} sx={styles.button}>
            <Typography variant="h2" sx={styles.buttonText}>
              <FormattedMessage id="general.bodyAtlas" />
            </Typography>
          </Button>
        </Box>
      </Container>
    </Layout>
  )
}

export default FrontPage
