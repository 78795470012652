/* tslint:disable */
/* eslint-disable */
/**
 * Migsosu BFF
 * Backend for frontend API for Migsosu app.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BodyPart,
  Category,
  CreateUserInfo,
  ErrorResponse,
  GenericResponse,
  GetFavorites200Response,
  GetShowRating200Response,
  Sentence,
  SubCategory,
  Term,
  UpdateFavoritesInfo,
  UpdateRatingRequest,
  UserActivityLog,
} from '../models';
import {
    BodyPartFromJSON,
    BodyPartToJSON,
    CategoryFromJSON,
    CategoryToJSON,
    CreateUserInfoFromJSON,
    CreateUserInfoToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    GenericResponseFromJSON,
    GenericResponseToJSON,
    GetFavorites200ResponseFromJSON,
    GetFavorites200ResponseToJSON,
    GetShowRating200ResponseFromJSON,
    GetShowRating200ResponseToJSON,
    SentenceFromJSON,
    SentenceToJSON,
    SubCategoryFromJSON,
    SubCategoryToJSON,
    TermFromJSON,
    TermToJSON,
    UpdateFavoritesInfoFromJSON,
    UpdateFavoritesInfoToJSON,
    UpdateRatingRequestFromJSON,
    UpdateRatingRequestToJSON,
    UserActivityLogFromJSON,
    UserActivityLogToJSON,
} from '../models';

export interface CreateUserRequest {
    xCSRFToken: string;
    createUserInfo: CreateUserInfo;
}

export interface GetCategoryRequest {
    categoryName: string;
}

export interface GetFavoritesRequest {
    userID: string;
    listName: string;
}

export interface GetSentencesRequest {
    categoryName: string;
}

export interface GetSubcategoriesRequest {
    categoryName: string;
}

export interface GetSubcategoryRequest {
    categoryName: string;
    subCategoryName: string;
}

export interface GetTermsRequest {
    categoryName: string;
    subCategoryName: string;
}

export interface LogActivityRequest {
    xCSRFToken: string;
    userActivityLog: UserActivityLog;
}

export interface ModifyFavoritesListRequest {
    userID: string;
    listName: string;
    action: string;
    xCSRFToken: string;
    updateFavoritesInfo: UpdateFavoritesInfo;
}

export interface UpdateRatingOperationRequest {
    xCSRFToken: string;
    updateRatingRequest?: UpdateRatingRequest;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Creates new user
     */
    async createUserRaw(requestParameters: CreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GenericResponse>> {
        if (requestParameters.xCSRFToken === null || requestParameters.xCSRFToken === undefined) {
            throw new runtime.RequiredError('xCSRFToken','Required parameter requestParameters.xCSRFToken was null or undefined when calling createUser.');
        }

        if (requestParameters.createUserInfo === null || requestParameters.createUserInfo === undefined) {
            throw new runtime.RequiredError('createUserInfo','Required parameter requestParameters.createUserInfo was null or undefined when calling createUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xCSRFToken !== undefined && requestParameters.xCSRFToken !== null) {
            headerParameters['X-CSRFToken'] = String(requestParameters.xCSRFToken);
        }

        const response = await this.request({
            path: `/createUser/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUserInfoToJSON(requestParameters.createUserInfo),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GenericResponseFromJSON(jsonValue));
    }

    /**
     * Creates new user
     */
    async createUser(requestParameters: CreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GenericResponse> {
        const response = await this.createUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list of body part roots
     */
    async getBodyPartsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BodyPart>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/bodyParts/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BodyPartFromJSON));
    }

    /**
     * Get list of body part roots
     */
    async getBodyParts(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BodyPart>> {
        const response = await this.getBodyPartsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get list of category names
     */
    async getCategoriesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Category>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/categories/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CategoryFromJSON));
    }

    /**
     * Get list of category names
     */
    async getCategories(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Category>> {
        const response = await this.getCategoriesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get category by name
     */
    async getCategoryRaw(requestParameters: GetCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Category>> {
        if (requestParameters.categoryName === null || requestParameters.categoryName === undefined) {
            throw new runtime.RequiredError('categoryName','Required parameter requestParameters.categoryName was null or undefined when calling getCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/categories/{categoryName}/`.replace(`{${"categoryName"}}`, encodeURIComponent(String(requestParameters.categoryName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoryFromJSON(jsonValue));
    }

    /**
     * Get category by name
     */
    async getCategory(requestParameters: GetCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Category> {
        const response = await this.getCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of favorite sentences for a given user and list name
     */
    async getFavoritesRaw(requestParameters: GetFavoritesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetFavorites200Response>> {
        if (requestParameters.userID === null || requestParameters.userID === undefined) {
            throw new runtime.RequiredError('userID','Required parameter requestParameters.userID was null or undefined when calling getFavorites.');
        }

        if (requestParameters.listName === null || requestParameters.listName === undefined) {
            throw new runtime.RequiredError('listName','Required parameter requestParameters.listName was null or undefined when calling getFavorites.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/favorites/{listName}/`.replace(`{${"userID"}}`, encodeURIComponent(String(requestParameters.userID))).replace(`{${"listName"}}`, encodeURIComponent(String(requestParameters.listName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFavorites200ResponseFromJSON(jsonValue));
    }

    /**
     * Get a list of favorite sentences for a given user and list name
     */
    async getFavorites(requestParameters: GetFavoritesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetFavorites200Response> {
        const response = await this.getFavoritesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list of Sentences for a given category name
     */
    async getSentencesRaw(requestParameters: GetSentencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Sentence>>> {
        if (requestParameters.categoryName === null || requestParameters.categoryName === undefined) {
            throw new runtime.RequiredError('categoryName','Required parameter requestParameters.categoryName was null or undefined when calling getSentences.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/categories/{categoryName}/sentences/`.replace(`{${"categoryName"}}`, encodeURIComponent(String(requestParameters.categoryName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SentenceFromJSON));
    }

    /**
     * Get list of Sentences for a given category name
     */
    async getSentences(requestParameters: GetSentencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Sentence>> {
        const response = await this.getSentencesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get boolean determining whether the rating dialog should be shown
     */
    async getShowRatingRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetShowRating200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rating/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetShowRating200ResponseFromJSON(jsonValue));
    }

    /**
     * Get boolean determining whether the rating dialog should be shown
     */
    async getShowRating(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetShowRating200Response> {
        const response = await this.getShowRatingRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get list of SubCategories for a given category name
     */
    async getSubcategoriesRaw(requestParameters: GetSubcategoriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SubCategory>>> {
        if (requestParameters.categoryName === null || requestParameters.categoryName === undefined) {
            throw new runtime.RequiredError('categoryName','Required parameter requestParameters.categoryName was null or undefined when calling getSubcategories.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/categories/{categoryName}/subcategories/`.replace(`{${"categoryName"}}`, encodeURIComponent(String(requestParameters.categoryName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SubCategoryFromJSON));
    }

    /**
     * Get list of SubCategories for a given category name
     */
    async getSubcategories(requestParameters: GetSubcategoriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SubCategory>> {
        const response = await this.getSubcategoriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a SubCategory for a given category and subcategory name
     */
    async getSubcategoryRaw(requestParameters: GetSubcategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubCategory>> {
        if (requestParameters.categoryName === null || requestParameters.categoryName === undefined) {
            throw new runtime.RequiredError('categoryName','Required parameter requestParameters.categoryName was null or undefined when calling getSubcategory.');
        }

        if (requestParameters.subCategoryName === null || requestParameters.subCategoryName === undefined) {
            throw new runtime.RequiredError('subCategoryName','Required parameter requestParameters.subCategoryName was null or undefined when calling getSubcategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/categories/{categoryName}/subcategories/{subCategoryName}/`.replace(`{${"categoryName"}}`, encodeURIComponent(String(requestParameters.categoryName))).replace(`{${"subCategoryName"}}`, encodeURIComponent(String(requestParameters.subCategoryName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubCategoryFromJSON(jsonValue));
    }

    /**
     * Get a SubCategory for a given category and subcategory name
     */
    async getSubcategory(requestParameters: GetSubcategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubCategory> {
        const response = await this.getSubcategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list of Terms for a given category and subcategory name
     */
    async getTermsRaw(requestParameters: GetTermsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Term>>> {
        if (requestParameters.categoryName === null || requestParameters.categoryName === undefined) {
            throw new runtime.RequiredError('categoryName','Required parameter requestParameters.categoryName was null or undefined when calling getTerms.');
        }

        if (requestParameters.subCategoryName === null || requestParameters.subCategoryName === undefined) {
            throw new runtime.RequiredError('subCategoryName','Required parameter requestParameters.subCategoryName was null or undefined when calling getTerms.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/categories/{categoryName}/subcategories/{subCategoryName}/terms/`.replace(`{${"categoryName"}}`, encodeURIComponent(String(requestParameters.categoryName))).replace(`{${"subCategoryName"}}`, encodeURIComponent(String(requestParameters.subCategoryName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TermFromJSON));
    }

    /**
     * Get list of Terms for a given category and subcategory name
     */
    async getTerms(requestParameters: GetTermsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Term>> {
        const response = await this.getTermsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Log the acticity of a user
     */
    async logActivityRaw(requestParameters: LogActivityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GenericResponse>> {
        if (requestParameters.xCSRFToken === null || requestParameters.xCSRFToken === undefined) {
            throw new runtime.RequiredError('xCSRFToken','Required parameter requestParameters.xCSRFToken was null or undefined when calling logActivity.');
        }

        if (requestParameters.userActivityLog === null || requestParameters.userActivityLog === undefined) {
            throw new runtime.RequiredError('userActivityLog','Required parameter requestParameters.userActivityLog was null or undefined when calling logActivity.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xCSRFToken !== undefined && requestParameters.xCSRFToken !== null) {
            headerParameters['X-CSRFToken'] = String(requestParameters.xCSRFToken);
        }

        const response = await this.request({
            path: `/userActivityLog/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserActivityLogToJSON(requestParameters.userActivityLog),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GenericResponseFromJSON(jsonValue));
    }

    /**
     * Log the acticity of a user
     */
    async logActivity(requestParameters: LogActivityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GenericResponse> {
        const response = await this.logActivityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add or remove a sentence or term for a favorites list
     */
    async modifyFavoritesListRaw(requestParameters: ModifyFavoritesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GenericResponse>> {
        if (requestParameters.userID === null || requestParameters.userID === undefined) {
            throw new runtime.RequiredError('userID','Required parameter requestParameters.userID was null or undefined when calling modifyFavoritesList.');
        }

        if (requestParameters.listName === null || requestParameters.listName === undefined) {
            throw new runtime.RequiredError('listName','Required parameter requestParameters.listName was null or undefined when calling modifyFavoritesList.');
        }

        if (requestParameters.action === null || requestParameters.action === undefined) {
            throw new runtime.RequiredError('action','Required parameter requestParameters.action was null or undefined when calling modifyFavoritesList.');
        }

        if (requestParameters.xCSRFToken === null || requestParameters.xCSRFToken === undefined) {
            throw new runtime.RequiredError('xCSRFToken','Required parameter requestParameters.xCSRFToken was null or undefined when calling modifyFavoritesList.');
        }

        if (requestParameters.updateFavoritesInfo === null || requestParameters.updateFavoritesInfo === undefined) {
            throw new runtime.RequiredError('updateFavoritesInfo','Required parameter requestParameters.updateFavoritesInfo was null or undefined when calling modifyFavoritesList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xCSRFToken !== undefined && requestParameters.xCSRFToken !== null) {
            headerParameters['X-CSRFToken'] = String(requestParameters.xCSRFToken);
        }

        const response = await this.request({
            path: `/favorites/{listName}/{action}/`.replace(`{${"userID"}}`, encodeURIComponent(String(requestParameters.userID))).replace(`{${"listName"}}`, encodeURIComponent(String(requestParameters.listName))).replace(`{${"action"}}`, encodeURIComponent(String(requestParameters.action))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateFavoritesInfoToJSON(requestParameters.updateFavoritesInfo),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GenericResponseFromJSON(jsonValue));
    }

    /**
     * Add or remove a sentence or term for a favorites list
     */
    async modifyFavoritesList(requestParameters: ModifyFavoritesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GenericResponse> {
        const response = await this.modifyFavoritesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update rating state
     */
    async updateRatingRaw(requestParameters: UpdateRatingOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GenericResponse>> {
        if (requestParameters.xCSRFToken === null || requestParameters.xCSRFToken === undefined) {
            throw new runtime.RequiredError('xCSRFToken','Required parameter requestParameters.xCSRFToken was null or undefined when calling updateRating.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xCSRFToken !== undefined && requestParameters.xCSRFToken !== null) {
            headerParameters['X-CSRFToken'] = String(requestParameters.xCSRFToken);
        }

        const response = await this.request({
            path: `/rating/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateRatingRequestToJSON(requestParameters.updateRatingRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GenericResponseFromJSON(jsonValue));
    }

    /**
     * Update rating state
     */
    async updateRating(requestParameters: UpdateRatingOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GenericResponse> {
        const response = await this.updateRatingRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
