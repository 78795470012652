import { useEffect, useMemo } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { Container, CircularProgress, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { styles } from './FavoritesPage.style'

import Layout from '../layout/Layout'
import SentenceList from '../sentencelist/SentenceList'
import FavoriteItem from '../favoriteitem/FavoriteItem'
import { useFavorites } from '../../api/hooks/useFavoritesQuery'
import { useLogging } from '../../api/hooks/useLoggingMutation'

// TODO: Remove when query is available
// import favorites from '../../mocks/data/favorites.json'
import { paths } from '../../paths'
import IconComponent from '../iconcomponent/IconComponent'
import {
  sentenceToAudioItemProps,
  termToAudioItemProps,
} from '../../helpers/audio-util'

export type FavoritesPageProps = {
  logout: () => void
}

/**
 * FavoritesPage displays a list of favorite items.
 * @param props Properties for the component.
 * @returns React component.
 */
export const FavoritesPage = ({ logout }: FavoritesPageProps) => {
  const navigate = useNavigate()
  const navigateBack = (): void => {
    navigate(paths.home)
  }
  const intl = useIntl()
  const {
    data: favorites,
    isLoading: isLoadingFavorites,
    isError: isErrorFavorites,
  } = useFavorites()

  const logger = useLogging()

  // Not sure if this is abuse of 'useEffect'
  useEffect(() => {
    logger.mutate({ activityType: 'NavigateFavorites' })
    // eslint-disable-next-line
  }, [])

  const sentenceItemsWithDivider = useMemo(() => {
    const sentenceItems = favorites?.sentences.map(sentence => {
      const audioItemProps = sentenceToAudioItemProps(sentence)
      audioItemProps.content.isFavorite = true // TODO: Maybe not the way
      return <FavoriteItem key={sentence.uuid} {...audioItemProps} />
    })

    if (sentenceItems === undefined || sentenceItems.length === 0) {
      return []
    } else {
      return [
        <Typography sx={styles.subHeader} variant="h3" key="sentenceDivider">
          <FormattedMessage id="general.languagehelp" />
        </Typography>,
      ].concat(sentenceItems)
    }
  }, [favorites?.sentences])

  const termItemsWithDivider = useMemo(() => {
    const termItems = favorites?.terms.map(term => {
      const audioItemProps = termToAudioItemProps(term)
      audioItemProps.content.isFavorite = true // TODO: Maybe not the way
      return <FavoriteItem key={term.uuid} {...audioItemProps} />
    })

    if (termItems === undefined || termItems.length === 0) {
      return []
    } else {
      return [
        <Typography sx={styles.subHeader} variant="h3" key="termDivider">
          <FormattedMessage id="general.termsHelp" />
        </Typography>,
      ].concat(termItems)
    }
  }, [favorites?.terms])

  if (isErrorFavorites) {
    return <FormattedMessage id="general.error" />
  }

  if (isLoadingFavorites) {
    return <CircularProgress />
  }

  if (favorites === undefined) {
    return <FormattedMessage id="general.error" />
  }

  return (
    <Layout title="general.appname" logout={logout}>
      <Container maxWidth="md" sx={styles.content}>
        <SentenceList
          navigateBack={navigateBack}
          icon={<IconComponent name="Favorite" />}
          title={intl.formatMessage({ id: 'favorites.favorites' })}
          description={intl.formatMessage({ id: 'sentences.instruction' })}
        >
          {sentenceItemsWithDivider.concat(termItemsWithDivider)}
        </SentenceList>
      </Container>
    </Layout>
  )
}

export default FavoritesPage
