import { UseMutationResult } from 'react-query'
import { GenericResponse, ErrorResponse } from '../../openapi/api'
import { useApi } from '../ApiProvider'

export const useFavoritesAdd = (): UseMutationResult<
  GenericResponse,
  ErrorResponse,
  { id: string; favoriteType: 'sentence' | 'term'; listName?: string }
> => {
  const { useFavoritesAddMutation } = useApi()
  return useFavoritesAddMutation()
}
