import { FC, useEffect, useState } from 'react'
import LayoutLogin from '../layoutLogin/LayoutLogin'
import {
  Box,
  Button,
  Typography,
  TextField,
  MenuItem,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { styles } from './CreateUserPage.style'
import { useAppDispatch, useAppSelector } from '../../state/hooks'
import { authActions } from '../../features/auth/authSlice'
import { useCreateUser } from '../../api/hooks/useCreateUserMutation'
import { isValidInput, cleanEmail } from '../../helpers/user-utils'
import gdprText from '../../data/gdpr.json'
import schools from '../../data/schools.json'
import regions from '../../data/regions.json'
import { useNavigate } from 'react-router'
import { paths } from '../../paths'

export interface CreateUserProps {
  login: (username: string, password: string) => void
}

export const CreateUserPage: FC<CreateUserProps> = ({
  login,
}: CreateUserProps) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [password1, setPassword1] = useState('')
  const [password2, setPassword2] = useState('')
  const [region, setRegion] = useState('')
  const [school, setSchool] = useState('')
  const [language, setLanguage] = useState('')
  const [errorId, setErrorId] = useState('')
  const [hasAcceptedConditions, setHasAcceptedConditions] = useState(false)
  const [isDialogOpen, setDialogOpen] = useState(false)
  const [isSuccessDialogOpen, setSuccessDialogOpen] = useState(false)
  const { isSuccess, isError, mutate: createUser } = useCreateUser()

  const { username } = useAppSelector(state => state.auth.user)

  const handleCreateClick = (): void => {
    const { isValid, errorId } = isValidInput(password1, password2, username)
    if (!isValid) {
      setErrorId(errorId)
      return
    }
    setErrorId('')
    console.log(
      'Attempting to create user:',
      username,
      password1,
      password2,
      region,
      school,
      language
    )
    if (hasAcceptedConditions) {
      attemptUserCreation()
    } else {
      displayDialog()
    }
  }

  const handleAgreeClick = (): void => {
    setDialogOpen(false)
    setHasAcceptedConditions(true)
    attemptUserCreation()
  }

  const handleClose = (): void => {
    setDialogOpen(false)
  }

  const displayDialog = (): void => {
    setDialogOpen(true)
  }

  const closeSuccessDialog = (): void => {
    setSuccessDialogOpen(false)
    navigate(paths.login) // Should not be needed, but if you ever succeed in creating user, but login fails for some reason, this will ensure you end up at the login page.
    login(cleanEmail(username), password1)
  }

  const attemptUserCreation = (): void => {
    createUser({
      email: cleanEmail(username),
      password: password1,
      firstName: '',
      lastName: '',
      motherTongue: language,
      regionId: region,
      school: school,
    })
  }

  useEffect(() => {
    if (isSuccess) {
      setSuccessDialogOpen(true)
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError) {
      setErrorId('createUser.invalidEmail')
    }
  }, [isError])

  return (
    <LayoutLogin title="general.appname" isLogin={false}>
      <Box sx={styles.content}>
        <Box sx={styles.loginTitleContainer}>
          <Typography variant="h2" sx={styles.text}>
            <FormattedMessage id="newUser.newUser" />
          </Typography>
        </Box>
        <Box sx={styles.textContainer}>
          <Typography variant="h5" sx={styles.text}>
            <FormattedMessage id="newUser.description" />
          </Typography>
        </Box>
        <Box sx={styles.inputContainer}>
          <TextField
            sx={styles.input}
            value={username}
            InputProps={{ disableUnderline: true, size: 'medium' }}
            variant="filled"
            label={intl.formatMessage({ id: 'login.email' })}
            required
            autoFocus
            onChange={e => {
              dispatch(authActions.setUsername(e.target.value))
            }}
          />
          <TextField
            sx={styles.input}
            InputProps={{ disableUnderline: true, size: 'medium' }}
            select
            value={region}
            variant="filled"
            label={intl.formatMessage({ id: 'newUser.region' })}
            required={false}
            onChange={e => {
              setRegion(e.target.value)
            }}
          >
            {regions.map(region => (
              <MenuItem key={region} value={region}>
                {region}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            sx={styles.input}
            InputProps={{ disableUnderline: true, size: 'medium' }}
            select
            value={school}
            variant="filled"
            label={intl.formatMessage({ id: 'newUser.school' })}
            required={false}
            onChange={e => {
              setSchool(e.target.value)
            }}
          >
            {schools.map(school => (
              <MenuItem key={school} value={school}>
                {school}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            sx={styles.input}
            InputProps={{ disableUnderline: true, size: 'medium' }}
            value={language}
            variant="filled"
            label={intl.formatMessage({ id: 'profile.motherTongue' })}
            required={false}
            onChange={e => {
              setLanguage(e.target.value)
            }}
          />
          <TextField
            sx={styles.input}
            InputProps={{ disableUnderline: true, size: 'medium' }}
            value={password1}
            variant="filled"
            label={intl.formatMessage({ id: 'login.password' })}
            type="password"
            required
            onChange={e => {
              setPassword1(e.target.value)
            }}
          />
          <TextField
            sx={styles.input}
            InputProps={{ disableUnderline: true, size: 'medium' }}
            value={password2}
            variant="filled"
            label={intl.formatMessage({ id: 'newUser.passwordRepeat' })}
            type="password"
            required
            onChange={e => {
              setPassword2(e.target.value)
            }}
          />
          {errorId !== '' && (
            <Typography sx={styles.errorText} variant="body2" component="div">
              <FormattedMessage id={errorId} />
            </Typography>
          )}
        </Box>
        <Button
          variant="text"
          sx={styles.createUserButton}
          onClick={handleCreateClick}
          disabled={false}
        >
          <Typography variant="h4">
            <FormattedMessage id="newUser.createUser" />
          </Typography>
        </Button>
        <Dialog open={isDialogOpen} onClose={handleClose}>
          <DialogTitle>
            {intl.formatMessage({ id: 'newUser.gdprTitle' })}
          </DialogTitle>
          <DialogContent>
            {
              // Extremely hacky way of formatting gdpr
              gdprText.map((text: string, index: number) => {
                let isHeader = false
                if (
                  text.length > 2 &&
                  !isNaN(+text.substring(0, 1)) &&
                  text.substring(1, 3) === '. '
                ) {
                  isHeader = true
                }
                const variant = isHeader ? 'body1' : 'body2'
                return (
                  <div key={index}>
                    {isHeader && <br />}
                    <Typography variant={variant} key={index}>
                      {text}
                    </Typography>
                  </div>
                )
              })
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
              {intl.formatMessage({ id: 'newUser.reject' })}
            </Button>
            <Button onClick={handleAgreeClick} autoFocus>
              {intl.formatMessage({ id: 'newUser.accept' })}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={isSuccessDialogOpen} onClose={closeSuccessDialog}>
          <DialogContent>
            <Typography>
              <FormattedMessage id="createUser.success" />
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeSuccessDialog}>
              {intl.formatMessage({ id: 'createUser.continue' })}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </LayoutLogin>
  )
}
