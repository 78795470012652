import { FC } from 'react'
import { ListItem, AudioItemProps } from '../listitem/ListItem'

export interface FavoriteItemProps extends AudioItemProps {}

/**
 * FavoriteItem is a composition of SentenceItem to provide expand options button
 * @param props Properties for the component.
 * @returns React component.
 */
export const FavoriteItem: FC<FavoriteItemProps> = (
  props: FavoriteItemProps
) => {
  return <ListItem {...props} />
}

export default FavoriteItem
