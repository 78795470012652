import { CircularProgress, Container } from '@mui/material'

import { FormattedMessage, useIntl } from 'react-intl'

import Layout from '../layout/Layout'
import { useSubCategories } from '../../api/hooks/useSubCategoriesQuery'
import { useNavigate, useParams } from 'react-router'
import CategoryList from '../categorylist/CategoryList'
import CategoryItem from '../categoryitem/CategoryItem'
import { styles } from './SubCategoryPage.style'
import { paths } from '../../paths'
import { useEffect } from 'react'
import { useLogging } from '../../api/hooks/useLoggingMutation'

export interface SubCategoryPageProps {
  logout: () => void
}

/**
 * Display a list of subcategories
 * @param props Properties for the component.
 * @returns React component.
 */
export const SubCategoryPage = ({
  logout,
}: SubCategoryPageProps): JSX.Element => {
  const intl = useIntl()
  const navigate = useNavigate()
  const logger = useLogging()
  const { categoryName } = useParams()
  const {
    data: subCategories,
    isLoading,
    isError,
  } = useSubCategories(categoryName ?? '')

  useEffect(() => {
    logger.mutate({
      activityType: 'NavigateCategory',
      categoryName: categoryName,
      additionalInfo: JSON.stringify({
        mode: 'terms',
      }),
    })
    // eslint-disable-next-line
  }, [])

  if (isError) {
    return <FormattedMessage id="general.error" />
  }

  if (isLoading) {
    return <CircularProgress />
  }
  const categoryItems = subCategories?.map(category => {
    const subCategory = { ...category, baseCategoryName: categoryName }
    return (
      <CategoryItem key={category.uuid} category={subCategory} type={'terms'} />
    )
  })

  return (
    <Layout
      title="general.appname"
      logout={logout}
      showLanguageModeTabs
      languageMode="terms"
    >
      <Container maxWidth="md" sx={styles.content}>
        <CategoryList
          title={categoryName ?? ''}
          description={intl.formatMessage({ id: 'categories.description' })}
          navigateBack={() => {
            navigate(`${paths.categories}/subcategories`)
          }}
          languageMode="terms"
        >
          {categoryItems}
        </CategoryList>
      </Container>
    </Layout>
  )
}

export default SubCategoryPage
