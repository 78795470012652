import { createStyles } from '../../theme'

export const styles = createStyles({
  content: {
    padding: '27px 26px 0px 26px',
  },
  subHeader: {
    marginBottom: 1,
    marginTop: 3,
  },
})
