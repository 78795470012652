import { createStyles } from '../../theme'

export const styles = createStyles({
  root: {
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    textAlign: 'left',
    padding: '18px 14px 18px 14px',
    margin: '7px 0 7px 0',
    width: '100%',
  },
  icon: {
    marginRight: '14px',
    width: '39px',
    height: '46px',
  },
})
