import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface User {
  username: string
  firstName?: string
  lastName?: string
  email: string
  regionId?: string
  school?: string
  motherTongue?: string
  groups: string[]
}

export interface AuthState {
  user: User
  csrf: string
  isAuthenticated: boolean
}

export const authInitialState: AuthState = {
  user: {
    username: '',
    firstName: undefined,
    lastName: undefined,
    regionId: undefined,
    motherTongue: undefined,
    school: undefined,
    email: '',
    groups: [],
  },
  csrf: '',
  isAuthenticated: false,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    setUsername: (state: AuthState, action: PayloadAction<string>) => {
      state.user.username = action.payload
    },
    setUser: (state: AuthState, action: PayloadAction<User>) => {
      state.user = action.payload
    },
    setCSRF: (state: AuthState, action: PayloadAction<string | null>) => {
      state.csrf = action.payload == null ? '' : action.payload
    },
    setIsAuthenticated: (state: AuthState, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const authActions = authSlice.actions
export default authSlice.reducer
