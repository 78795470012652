import { AudioItemProps } from '../components/listitem/ListItem'
import { Sentence, Term } from '../openapi/api'

export const sentenceToAudioItemProps = (
  sentence: Sentence
): AudioItemProps => {
  return {
    content: {
      type: 'sentence',
      uuid: sentence.uuid,
      transcript: sentence.transcript,
      audioUrl: sentence.audioUrl,
      isFavorite: sentence.isFavorite,
    },
  }
}

export const termToAudioItemProps = (term: Term): AudioItemProps => {
  return {
    content: {
      type: 'term',
      uuid: term.uuid,
      title: term.title,
      description: term.description,
      imageUrl: term.imageUrl ?? undefined,
      audioUrl: term.audioUrl ?? undefined,
      isFavorite: term.isFavorite,
    },
  }
}

export function matchFavorites<T extends Sentence | Term>(
  items: T[],
  favorites: T[]
): T[] {
  const favoriteIds: string[] = favorites.map(s => s.uuid)
  return items.map(s => {
    s.isFavorite = favoriteIds.includes(s.uuid)
    return s
  })
}
