import { useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material'
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'

import { styles } from './SearchBar.style'

import { paths } from '../../paths'
import { useAllSentences } from '../../api/hooks/useAllSentencesQuery'
import { useAllTerms } from '../../api/hooks/useAllTermsQuery'

type SearchEntry = {
  label: string // What is actually searched in
  display: JSX.Element // How to display the entry
  linkUrl: string // Where to go if we click the entry
  uuid: string
}

interface SearchBarProps {
  languageMode?: 'terms' | 'sentences'
}

/**
 * SearchBar displays a textfield for searching in sentences and a list with links to matching sentences.
 * @returns React component.
 */
export const SearchBar = ({ languageMode }: SearchBarProps) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const {
    data: sentences,
    isLoading: isLoadingSentences,
    isError: isErrorSentences,
  } = useAllSentences()
  const {
    data: terms,
    isLoading: isLoadingTerms,
    isError: isErrorTerms,
  } = useAllTerms()

  // Create search entries
  const searchEntries = useMemo(() => {
    const termSearchEntries = terms?.map(term => ({
      label: `${term.title};${term.description}`,
      display: (
        <>
          <Typography variant="h3" sx={styles.searchOptionTitle}>
            {term.title}
          </Typography>
          <Box sx={styles.searchOptionCategoryContainer}>
            <SubdirectoryArrowRightIcon sx={styles.categoryIcon} />
            <Typography variant="body2" sx={styles.searchOptionCategoryText}>
              {term.category}
              <ArrowRightAltIcon sx={styles.categoryIcon} />
              {term.subcategory}
            </Typography>
          </Box>
        </>
      ),
      linkUrl: `${paths.categories}/${term.category}${paths.subcategories}/${term.subcategory}${paths.terms}`,
      uuid: term.uuid,
      entryType: 'term',
    }))
    const sentenceSearchEntries = sentences?.map(sentence => ({
      label: sentence.transcript,
      display: (
        <>
          <Typography variant="h3" sx={styles.searchOptionTitle}>
            {sentence.transcript}
          </Typography>
          <Box sx={styles.searchOptionCategoryContainer}>
            <SubdirectoryArrowRightIcon sx={styles.categoryIcon} />
            <Typography variant="body2" sx={styles.searchOptionCategoryText}>
              {sentence.category}
            </Typography>
          </Box>
        </>
      ),
      linkUrl: `${paths.categories}/${sentence.category}${paths.sentences}`,
      uuid: sentence.uuid,
      entryType: 'sentence',
    }))

    // Only include search entries for the active mode, if no mode (frontpage) is selected include all
    let searchEntries
    switch (languageMode) {
      case 'terms':
        searchEntries = termSearchEntries
        break
      case 'sentences':
        searchEntries = sentenceSearchEntries
        break
      case undefined:
        searchEntries = sentenceSearchEntries?.concat(termSearchEntries ?? [])
        break
    }
    return searchEntries?.sort((a, b) =>
      a.label > b.label ? 1 : a.label < b.label ? -1 : 0
    ) // Sort entries alphabetically
  }, [terms, sentences, languageMode])

  // Navigate to clicked category, and close and clear search field
  const handleOptionClick = (option: SearchEntry): void => {
    navigate(option.linkUrl)
    setIsOpen(false)
    setInputValue('')
  }

  // Update input field and show options
  const handleInputChange = (_: unknown, newInputValue: string): void => {
    setIsOpen(newInputValue.length > 0)
    setInputValue(newInputValue.replaceAll(';', ''))
  }

  if (isErrorSentences || isErrorTerms) {
    return (
      <Typography>
        <FormattedMessage id="general.error" />
      </Typography>
    )
  }

  return (
    <Autocomplete
      popupIcon={<></>}
      sx={styles.root}
      loading={isLoadingSentences || isLoadingTerms}
      options={searchEntries ?? []}
      open={isOpen}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      getOptionLabel={option => option.label}
      renderOption={(_, option) => (
        <Button
          sx={styles.searchOptionButton}
          key={option.uuid + option.entryType}
          onClick={() => handleOptionClick(option)}
        >
          {option.display}
        </Button>
      )}
      renderInput={params => {
        return (
          <TextField
            autoFocus
            variant="filled"
            {...params}
            InputProps={{ ...params.InputProps, disableUnderline: true }}
            sx={styles.textInput}
            label={intl.formatMessage({ id: 'search.searchBarPlaceholder' })}
          />
        )
      }}
    />
  )
}

export default SearchBar
