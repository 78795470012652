/* tslint:disable */
/* eslint-disable */
/**
 * Migsosu BFF
 * Backend for frontend API for Migsosu app.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUserInfo
 */
export interface CreateUserInfo {
    /**
     * 
     * @type {string}
     * @memberof CreateUserInfo
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserInfo
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserInfo
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserInfo
     */
    motherTongue: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserInfo
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserInfo
     */
    regionId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserInfo
     */
    school: string;
}

/**
 * Check if a given object implements the CreateUserInfo interface.
 */
export function instanceOfCreateUserInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "motherTongue" in value;
    isInstance = isInstance && "password" in value;
    isInstance = isInstance && "regionId" in value;
    isInstance = isInstance && "school" in value;

    return isInstance;
}

export function CreateUserInfoFromJSON(json: any): CreateUserInfo {
    return CreateUserInfoFromJSONTyped(json, false);
}

export function CreateUserInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUserInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'motherTongue': json['motherTongue'],
        'password': json['password'],
        'regionId': json['regionId'],
        'school': json['school'],
    };
}

export function CreateUserInfoToJSON(value?: CreateUserInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'motherTongue': value.motherTongue,
        'password': value.password,
        'regionId': value.regionId,
        'school': value.school,
    };
}

