import { UseMutationResult } from 'react-query'
import {
  GenericResponse,
  ErrorResponse,
  UserActivityLog,
} from '../../openapi/api'
import { useApi } from '../ApiProvider'

export const useLogging = (): UseMutationResult<
  GenericResponse,
  ErrorResponse,
  UserActivityLog
> => {
  const { useLoggingMutation } = useApi()
  return useLoggingMutation()
}
