import { createContext, useContext } from 'react'

/**
 * Context which exposes controls and properties of the current audio track
 */
export interface IPlaybackContext {
  /**
   * Duraction of audio track
   */
  duration: number

  /**
   * Controls for the playback position of the current audio track
   */
  playbackPosition: number
  setPlaybackPosition: (position: number) => void

  /**
   * Controls for playing/pausing audio
   */
  isPlaying: boolean
  play: () => void
  pause: () => void
  reset: () => void

  /**
   * Controls for playback rate
   */
  playbackRate: number
  setPlaybackRate: (rate: number) => void
}

export const PlaybackContext = createContext<IPlaybackContext | null>(null)

export const usePlayback = (): IPlaybackContext | null => {
  return useContext(PlaybackContext)
}
