import { FC, KeyboardEvent, useState } from 'react'
import {
  Box,
  Button,
  TextField,
  Typography,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material'
import { styles } from './LoginPage.style'
import { FormattedMessage, useIntl } from 'react-intl'
import { useAppDispatch, useAppSelector } from '../../state/hooks'
import { authActions } from '../../features/auth/authSlice'
import LayoutLogin from '../layoutLogin/LayoutLogin'
import { useNavigate } from 'react-router'
import { paths } from '../../paths'
import { cleanEmail } from '../../helpers/user-utils'

export interface LoginPageProps {
  isLoading: boolean
  isError: boolean
  login: (username: string, password: string) => void
}

/**
 * LoginPage provides the option to login to the platform using user credentials.
 * @param props Properties for the component.
 * @returns React component.
 */
export const LoginPage: FC<LoginPageProps> = ({
  isLoading,
  isError,
  login,
}: LoginPageProps) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [isDialogOpen, setDialogOpen] = useState(false)
  const { username } = useAppSelector(state => state.auth.user)

  const [password, setPassword] = useState<string>('')

  const handleLogin = (): void => {
    login(cleanEmail(username), password)
  }

  const handleKeyPress = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter') {
      handleLogin()
    }
  }

  return (
    <LayoutLogin title="general.appname" isLogin>
      <Box sx={styles.content}>
        <Box sx={styles.loginTitleContainer}>
          <Typography variant="h2" sx={styles.text}>
            <FormattedMessage id="login.login" />
          </Typography>
        </Box>
        <Box sx={styles.textContainer}>
          <Typography variant="h5" sx={styles.text}>
            <FormattedMessage id="login.description" />
          </Typography>
        </Box>
        <Box sx={styles.inputContainer}>
          <TextField
            sx={styles.input}
            value={username}
            InputProps={{ disableUnderline: true, size: 'medium' }}
            variant="filled"
            label={intl.formatMessage({ id: 'login.email' })}
            required
            autoFocus
            onKeyPress={handleKeyPress}
            onChange={e => {
              dispatch(authActions.setUsername(e.target.value))
            }}
          />
          <TextField
            sx={styles.input}
            InputProps={{ disableUnderline: true, size: 'medium' }}
            value={password}
            variant="filled"
            label={intl.formatMessage({ id: 'login.password' })}
            type="password"
            required
            onKeyPress={handleKeyPress}
            onChange={e => {
              setPassword(e.target.value)
            }}
          />
          <Typography
            sx={isError ? styles.errorText : styles.hidden}
            variant="body2"
            component="div"
          >
            <FormattedMessage id="login.error.invalidcredentials" />
          </Typography>
        </Box>
        <Button
          variant="text"
          sx={styles.loginButton}
          onClick={handleLogin}
          disabled={isLoading}
        >
          <Typography variant="h4">
            <FormattedMessage id="login.login" />
          </Typography>
        </Button>
        <Button
          variant="text"
          sx={styles.newUserButton}
          onClick={() => navigate(paths.createUser)}
          disabled={isLoading}
        >
          <Typography variant="h5">
            <FormattedMessage id="newUser.createUser" />
          </Typography>
        </Button>
        <Link variant="body2" href="/accounts/password_reset">
          <FormattedMessage id="login.forgottenPassword" />
        </Link>
        <Dialog open={isDialogOpen} onClose={() => setDialogOpen(false)}>
          <DialogTitle>
            <FormattedMessage id="login.forgottenPassword" />
          </DialogTitle>
          <DialogContent>
            <FormattedMessage id="login.alexSupport" />
            <Link
              href={'mailto:' + intl.formatMessage({ id: 'login.alexEmail' })}
            >
              {intl.formatMessage({ id: 'login.alexEmail' })}
            </Link>
          </DialogContent>
        </Dialog>
      </Box>
    </LayoutLogin>
  )
}

export default LoginPage
