export const paths = {
  home: '/',
  login: '/login',
  categories: '/categories',
  favorites: '/favorites',
  profile: '/profile',
  createUser: '/createUser',
  bodyAtlas: '/bodyatlas',
  sentences: '/sentences',
  subcategories: '/subcategories',
  terms: '/terms',
}

export const unrestrictedPaths = [paths.login, paths.createUser]
export const filePaths = {
  usermanuaul: '/brugervejledning.pdf',
}
