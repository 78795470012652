import { colors, createStyles } from '../../theme'

import BackgroundSvg from '../../assets/layout_accent.svg'

export const styles = createStyles({
  root: {
    backgroundColor: colors.brandBase,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right top',
    backgroundImage: `url(${BackgroundSvg})`,
  },
  title: {
    color: colors.white,
  },
  contentLogin: {
    marginTop: '200px',
    backgroundColor: colors.white,
    borderRadius: '34px 0px 0px 0px',
  },
  content: {
    marginTop: '40px',
    backgroundColor: colors.white,
    borderRadius: '34px 0px 0px 0px',
  },
  topMenu: {
    display: 'flex',
  },
  searchBar: {
    backgroundColor: 'white',
    borderRadius: '8px',
    margin: '40px auto 0px auto',
    width: 364,
  },
  searchContainer: {
    width: '100%',
    display: 'flex',
    paddingTop: '12px',
  },
  favoriteIconButton: {
    color: colors.white,
  },
  menuIconButton: {
    color: colors.white,
  },
  searchIconButton: {
    marginLeft: 'auto',
    color: colors.white,
  },
  menuButton: {
    width: '100%',
  },
  menuContainer: {
    paddingTop: '22px',
    height: '100%',
    width: '33vw',
    backgroundColor: 'white',
  },
})
