import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  Tooltip,
  Typography,
} from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'

import { styles } from './ListItem.style'
import { AudioDetails } from '../audiodetails/AudioDetails'
import AudioDetailsFull from '../audiodetailsfull/AudioDetailsFull'
import { PlayArrow } from '@mui/icons-material'
import { useLogging } from '../../api/hooks/useLoggingMutation'
import { PlaybackContext } from '../../features/playback/playbackContext'
import { Playback, usePlayback } from '../../api/hooks/usePlayback'

export interface SentenceContent {
  type: 'sentence'
  uuid: string
  transcript?: string
  audioUrl: string
  isFavorite?: boolean
}

export interface TermContent {
  type: 'term'
  uuid: string
  title: string
  description?: string
  imageUrl?: string
  audioUrl?: string
  isFavorite?: boolean
}

export interface AudioItemProps {
  isDetailsOpen?: boolean
  openDetails?: (open: boolean) => void
  content: SentenceContent | TermContent
  children?: never
}

/**
 * ListItem displays a category button with title and an icon. Wrapper component to handle
 * correct handling of audio content, in a react hook friendly manner
 * @param props Properties for the component.
 * @returns React component.
 */
export const ListItem = (props: AudioItemProps) => {
  return (
    <>
      {props.content.audioUrl !== undefined ? (
        <ListItemAudio {...props} />
      ) : (
        <AudioItem {...props} />
      )}
    </>
  )
}

/**
 * Wrapper component to handle when a list item has audio, in a way that works with react hooks
 * @param props
 * @returns React component
 */
const ListItemAudio = (props: AudioItemProps) => {
  const playback = usePlayback(props.content.audioUrl ?? '')
  return <AudioItem {...props} playbackWrapper={playback} />
}

/**
 * AudioItem displays a category button with title and an icon.
 * @param props Properties for the component.
 * @returns React component.
 */
const AudioItem = ({
  openDetails,
  isDetailsOpen,
  content,
  playbackWrapper,
}: AudioItemProps & { playbackWrapper?: Playback }) => {
  const intl = useIntl()
  const [isFullscreen, setIsFullscreen] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const { title, description, details } = (() => {
    switch (content.type) {
      case 'sentence': {
        const sentenceContent = content as SentenceContent
        const details = (
          <AudioDetails
            variant={content.type}
            setIsFullscreen={setIsFullscreen}
            openDetails={openDetails ?? ((open: boolean) => false)}
            transcript={sentenceContent.transcript ?? ''}
            isFavorite={content.isFavorite ?? false}
            Uuid={content.uuid}
          />
        )
        return {
          title: sentenceContent.transcript,
          description: sentenceContent.transcript,
          details: details,
        }
      }
      case 'term': {
        const termContent = content as TermContent
        const details = (
          <AudioDetails
            variant={content.type}
            title={termContent.title ?? ''}
            setIsFullscreen={setIsFullscreen}
            openDetails={openDetails ?? ((open: boolean) => false)}
            description={termContent.description ?? ''}
            imageUrl={termContent.imageUrl}
            isFavorite={content.isFavorite ?? false}
            Uuid={content.uuid}
          />
        )
        return {
          title: termContent.title,
          description: termContent.description,
          details: details,
        }
      }
    }
  })()

  const { mutate: log } = useLogging()

  const playback = playbackWrapper?.playback

  const { isPlaying, play, reset } =
    playback !== undefined
      ? playback
      : {
          isPlaying: false,
          play: () => {},
          reset: () => {},
        }

  // Effect for resetting sound if drawer is closed
  useEffect(() => {
    if (isDetailsOpen !== undefined) {
      if (!isDetailsOpen) {
        reset()
      }
    }
  }, [isDetailsOpen, reset])

  const handleClick = (): void => {
    if (openDetails !== undefined && isDetailsOpen !== undefined) {
      const additionalInfo = {
        detailsOpen: isDetailsOpen,
        isPlaying: isPlaying,
        termId: content.type === 'term' ? content.uuid : undefined, // Add termId as additional info
      }
      log({
        activityType: 'AudioItemClick',
        sentenceID: content.type === 'sentence' ? content.uuid : undefined, // Only include uuid if sentence (only part implemented in DB schema)
        additionalInfo: JSON.stringify(additionalInfo),
      })
      // If not already open play clip
      if (!isDetailsOpen) {
        play()
      }
      openDetails(!isDetailsOpen)
    }
  }

  const hasError = playbackWrapper?.error ?? false
  const isLoading = playbackWrapper?.loading ?? false

  return (
    <>
      <Tooltip
        title={intl.formatMessage({ id: 'sentences.loadingerror' })}
        open={tooltipOpen}
        onClose={() => setTooltipOpen(false)}
        leaveTouchDelay={5000}
        arrow
      >
        <Box
          component="span"
          onClick={() => {
            setTooltipOpen(hasError ? !tooltipOpen : false)
          }}
        >
          <Button
            disabled={isLoading || hasError}
            sx={styles.root}
            onClick={handleClick}
          >
            <Typography component="h2" variant="h5">
              {title ?? ''}
            </Typography>
            {hasError && (
              <Box sx={styles.additionalInfoContainer}>
                <ErrorIcon sx={styles.errorIcon} />
              </Box>
            )}
            {isLoading && !hasError && (
              <Box sx={styles.additionalInfoContainer}>
                <CircularProgress />
              </Box>
            )}
            {content.audioUrl != null && <PlayArrow sx={styles.playIcon} />}
          </Button>
        </Box>
      </Tooltip>
      <PlaybackContext.Provider value={playbackWrapper?.playback ?? null}>
        <Drawer
          transitionDuration={{}}
          anchor="bottom"
          open={isDetailsOpen}
          variant="persistent"
        >
          <Box sx={styles.detailsContainer}>{details}</Box>
        </Drawer>
        <Drawer
          anchor="bottom"
          open={isFullscreen}
          onClose={() => setIsFullscreen(false)}
        >
          <Box sx={[styles.detailsContainer, styles.detailsFullscreen]}>
            <AudioDetailsFull
              transcript={description ?? ''}
              setIsFullscreen={setIsFullscreen}
              sentenceID={content.uuid}
            />
          </Box>
        </Drawer>
      </PlaybackContext.Provider>
    </>
  )
}

export default AudioItem
