import { colors, createStyles, theme } from '../../theme'

export const styles = createStyles({
  root: {
    // This is the actual drawer container
    '& .MuiDrawer-paper': {
      backgroundColor: colors.dialog,
      borderRadius: '34px 0px 0px 0px',
    },
  },
  content: {
    padding: '22px 24px 10px 24px',
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
  },
  playIcon: {
    fontSize: '32px',
    color: theme.palette.common.white,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  drawerBody: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
  },
  expandIconButton: {
    marginLeft: 'auto',
  },
  description: {
    color: colors.white,
    textAlign: 'left',
    height: '80px',
    alignSelf: 'flex-start',
    marginLeft: `calc(${theme.spacing(2)} + 42px)`,
    marginRight: 3,
    overflow: 'hidden',
  },
  title: {
    color: colors.white,
    display: 'flex',
    alignItems: 'center',
    marginLeft: 2,
    fontSize: '42px',
  },
  iconButton: {
    color: colors.white,
    padding: 0,
  },
  playButton: {
    marginLeft: 'auto',
  },
  closeIcon: {
    fontSize: '42px',
  },
})
