import { FC } from 'react'

import { Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

import { styles } from './CategoryItem.style'
import { paths } from '../../paths'
import { Category } from '../../openapi/api'
import CategoryIcon from '../categoryicon/CategoryIcon'

export type CategoryType = 'sentences' | 'terms' | 'subcategories'

export interface SubCategory extends Category {
  baseCategoryName: string
}

export interface CategoryItemProps {
  category: Category | SubCategory
  type: CategoryType
}

/**
 * CategoryItem displays a category button with name and an icon.
 * @param props Properties for the component.
 * @returns React component.
 */
export const CategoryItem: FC<CategoryItemProps> = ({
  category,
  type,
}: CategoryItemProps) => {
  // TODO: Probably not ideal approach
  const typeSpecificURL = (() => {
    switch (type) {
      case 'sentences':
        return `${paths.categories}/${category.name}${paths.sentences}`
      case 'subcategories':
        return `${paths.categories}/${category.name}${paths.subcategories}`
      case 'terms': {
        const subcategory = category as SubCategory
        return `${paths.categories}/${subcategory.baseCategoryName}${paths.subcategories}/${subcategory.name}${paths.terms}`
      }
    }
  })()

  return (
    <Button component={Link} to={typeSpecificURL} sx={styles.root}>
      {category.iconSrc.length > 0 && (
        <CategoryIcon category={category} sx={styles.icon} />
      )}
      <Typography component="h2" variant="h3">
        {category.name}
      </Typography>
    </Button>
  )
}

export default CategoryItem
