import { FC, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import {
  Button,
  Container,
  Box,
  Typography,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { styles } from './ProfilePage.style'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import Layout from '../layout/Layout'

import { paths } from '../../paths'
import { useAppSelector } from '../../state/hooks'
import gdprText from '../../data/gdpr.json'
import IconComponent from '../iconcomponent/IconComponent'

export interface ProfilePageProps {
  logout: () => void
}

/**
 * ProfilePage displays a list of favorite items.
 * @param props Properties for the component.
 * @returns React component.
 */
export const ProfilePage: FC<ProfilePageProps> = ({
  logout,
}: ProfilePageProps) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [isDialogOpen, setDialogOpen] = useState(false)

  const handleClose = (): void => {
    setDialogOpen(false)
  }

  const { email, motherTongue, regionId, school } = useAppSelector(
    state => state.auth.user
  )

  const handleBackClick = (): void => {
    navigate(paths.home)
  }

  return (
    <Layout title="general.appname" logout={logout}>
      <Container maxWidth="md" sx={styles.content}>
        <Box sx={styles.root}>
          <Box sx={styles.header}>
            <IconComponent name="Profile" sx={styles.icon} />
            <Box>
              <Typography component="h1" variant="h2">
                {intl.formatMessage({ id: 'general.profile' })}
              </Typography>
              <Typography component="p" variant="body2">
                {intl.formatMessage({ id: 'profile.description' })}
              </Typography>
            </Box>
            <ArrowBackIcon sx={styles.backIcon} onClick={handleBackClick} />
          </Box>
          <TableContainer sx={styles.listContainer}>
            <Table>
              <TableBody sx={styles.tableBody}>
                <TableRow>
                  <TableCell sx={styles.tableCellTitle}>
                    <FormattedMessage id="profile.email" />
                  </TableCell>
                  <TableCell sx={styles.tableCellField}>{email}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <FormattedMessage id="profile.motherTongue" />
                  </TableCell>
                  <TableCell>
                    {motherTongue !== '' && motherTongue !== undefined
                      ? motherTongue
                      : '----'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <FormattedMessage id="profile.region" />
                  </TableCell>
                  <TableCell>
                    {regionId !== '' && regionId !== undefined
                      ? regionId
                      : '----'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <FormattedMessage id="profile.school" />
                  </TableCell>
                  <TableCell>
                    {school !== '' && school !== undefined ? school : '----'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Link
                      component="button"
                      variant="body2"
                      onClick={() => setDialogOpen(true)}
                    >
                      <FormattedMessage id="profile.terms" />
                    </Link>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Container>
      <Dialog open={isDialogOpen} onClose={handleClose}>
        <DialogTitle>
          {intl.formatMessage({ id: 'newUser.gdprTitle' })}
        </DialogTitle>
        <DialogContent>
          {
            // Extremely hacky way of formatting gdpr
            gdprText.map((text: string, index: number) => {
              let isHeader = false
              if (
                text.length > 2 &&
                !isNaN(+text.substring(0, 1)) &&
                text.substring(1, 3) === '. '
              ) {
                isHeader = true
              }
              const variant = isHeader ? 'body1' : 'body2'
              return (
                <div key={index}>
                  {isHeader && <br />}
                  <Typography variant={variant} key={index}>
                    {text}
                  </Typography>
                </div>
              )
            })
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            {intl.formatMessage({ id: 'profile.close' })}
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  )
}

export default ProfilePage
