import { colors, createStyles } from '../../theme'

export const styles = createStyles({
  root: {
    padding: '0px 32px 0px 32px',
    width: '100%',
    textAlign: 'center',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
  },
  transcript: {
    color: colors.white,
    padding: '0 22px 0 22px',
  },
  header: {
    display: 'flex',
  },
  playIcon: {
    color: colors.white,
    fontSize: '80px',
  },
  replayIcon: {
    color: colors.white,
    fontSize: '32px',
  },
  playbackSpeedButton: {
    color: colors.white,
  },
  playbackSlider: {
    margin: '42px auto 14px auto',
    color: colors.white,
  },
  controlsButton: {
    width: '33%',
  },
  controlsContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
  },
  closeIconButton: {
    color: colors.white,
    marginLeft: 'auto',
    padding: '6px 0 12px 0',
  },
  closeIcon: {
    fontSize: '62px',
  },
})
