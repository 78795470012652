import { createStyles } from '../../theme'

export const styles = createStyles({
  content: {
    padding: '27px 26px 0px 26px',
  },
  root: {
    marginBottom: '27px',
  },
  icon: {
    marginRight: '10px',
    fontSize: '72px',
    position: 'relative',
    top: '6px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '12px',
  },
  listContainer: {
    marginTop: '20px',
  },
  backIcon: {
    marginLeft: 'auto',
    fontSize: '32px',
  },
  tableCellTitle: {
    width: '30%',
  },
  tableCellField: {
    width: '70%',
  },
  tableBody: {
    width: '100%',
    display: 'table',
  },
})
