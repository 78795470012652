import { Box } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import {
  Navigate,
  NavigateOptions,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { authActions } from '../../features/auth/authSlice'
import { paths, unrestrictedPaths } from '../../paths'
import { useAppDispatch, useAppSelector } from '../../state/hooks'
import { useLogging } from '../../api/hooks/useLoggingMutation'

import FrontPage from '../frontpage/FrontPage'
import LoginPage from '../login/LoginPage'
import CategoryPage from '../categorypage/CategoryPage'
import SentencePage from '../sentencepage/SentencePage'
import FavoritesPage from '../favoritespage/FavoritesPage'
import ProfilePage from '../profilepage/ProfilePage'
import SubCategoryPage from '../subcategorypage/SubCategoryPage'
import { DefaultApi } from '../../openapi/api'
import { CreateUserPage } from '../createUser/CreateUserPage'
import { BodyAtlasPage } from '../bodyatlaspage/BodyAtlasPage'
import TermPage from '../termpage/TermPage'
import FeedbackDialog from '../feedbackdialog/FeedbackDialog'

export const AuthAwareRouter: FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const api = new DefaultApi()
  const logger = useLogging()

  const { isAuthenticated, csrf } = useAppSelector(state => state.auth)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)

  const getCSRF = async (logLogin?: boolean): Promise<void> => {
    fetch('/migsosu/csrf/', {
      credentials: 'same-origin',
    })
      .then(res => {
        const csrfToken = res.headers.get('X-CSRFToken')
        dispatch(authActions.setCSRF(csrfToken))
        if (logLogin === true && csrfToken != null) {
          void api.logActivity({
            userActivityLog: {
              activityType: 'Login',
              additionalInfo: `User Agent: ${window.navigator.userAgent}`,
            },
            xCSRFToken: csrfToken,
          })
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  const getSession = async (logLogin?: boolean): Promise<void> => {
    fetch('/migsosu/session/', {
      credentials: 'same-origin',
    })
      .then(isResponseOk)
      .then(data => {
        if (data.isAuthenticated === true) {
          // the linter complains that explicit comparrison is needed if it is just 'if (data.isAuthenticated)'
          dispatch(authActions.setIsAuthenticated(true))
          void whoami()
        } else {
          dispatch(authActions.setIsAuthenticated(false))
        }
        void getCSRF(logLogin)
      })
      .catch(err => {
        console.error(err)
      })
  }

  const whoami = async (): Promise<void> => {
    fetch('/migsosu/whoami/', {
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'same-origin',
    })
      .then(isResponseOk)
      .then(data => {
        console.log('You are logged in as: ' + String(data.username))
        dispatch(authActions.setUser(data))
      })
      .catch(err => {
        console.error(err)
      })
  }

  const isResponseOk = async (response: Response): Promise<any> => {
    if (response.status >= 200 && response.status <= 299) {
      return await response.json()
    } else {
      const responseJson = await response.json()
      // eslint-disable-next-line
      throw Error(`${response.statusText} - ${responseJson.detail}`)
    }
  }

  const login = async (username: string, password: string): Promise<void> => {
    setIsLoading(true)
    fetch('/migsosu/login/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrf,
      },
      credentials: 'same-origin',
      body: JSON.stringify({ username: username, password: password }),
    })
      .then(isResponseOk)
      .then(data => {
        dispatch(authActions.setIsAuthenticated(true))
        setIsError(false)
        void getSession(true)
      })
      .catch(err => {
        console.error(err)
        setIsError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const logout = async (): Promise<void> => {
    logger.mutate({ activityType: 'Logout' })

    fetch('/migsosu/logout', {
      credentials: 'same-origin',
    })
      .then(isResponseOk)
      .then(data => {
        dispatch(authActions.setIsAuthenticated(false))
        navigate(paths.login)
        void getCSRF()
      })
      .catch(err => {
        console.error(err)
      })
  }

  useEffect(() => {
    if (isAuthenticated) {
      logger.mutate({ activityType: 'SiteLoaded' }) // Log that site was loaded if we are already authenticated (React strict makes this run twice in development)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    void getSession()
    // eslint-disable-next-line
  }, [isAuthenticated])

  useEffect(() => {
    if (isAuthenticated) {
      if (location.pathname === paths.login) {
        navigate((location.state as any)?.from?.pathname ?? '')
      }
    } else {
      if (!unrestrictedPaths.includes(location.pathname)) {
        const options: NavigateOptions = {
          state: { from: location },
        }
        navigate(paths.login, options)
      }
    }
  }, [isAuthenticated, location, navigate])

  return (
    <Box>
      {isAuthenticated && (
        <Routes>
          <Route
            path={paths.home}
            element={
              <>
                <FeedbackDialog />
                <FrontPage logout={logout} />
              </>
            }
          />
          <Route
            path={`${paths.categories}/:type`}
            element={
              <>
                <FeedbackDialog />
                <CategoryPage logout={logout} />
              </>
            }
          />
          <Route
            path={`${paths.categories}/:categoryName${paths.sentences}`}
            element={
              <>
                <FeedbackDialog />
                <SentencePage logout={logout} />
              </>
            }
          />
          <Route
            path={`${paths.categories}/:categoryName${paths.subcategories}`}
            element={
              <>
                <FeedbackDialog />
                <SubCategoryPage logout={logout} />
              </>
            }
          />
          <Route
            path={`${paths.categories}/:categoryName${paths.subcategories}/:subcategoryName${paths.terms}`}
            element={
              <>
                <FeedbackDialog />
                <TermPage logout={logout} />
              </>
            }
          />
          <Route
            path={paths.favorites}
            element={
              <>
                <FeedbackDialog />
                <FavoritesPage logout={logout} />
              </>
            }
          />
          <Route
            path={paths.profile}
            element={
              <>
                <FeedbackDialog />
                <ProfilePage logout={logout} />
              </>
            }
          />
          <Route
            path={paths.bodyAtlas}
            element={
              <>
                <FeedbackDialog />
                <BodyAtlasPage logout={logout} />
              </>
            }
          />
          <Route
            path="*"
            element={
              <>
                <FeedbackDialog />
                <Navigate to={paths.home} state={{ from: location }} />
              </>
            }
          />
        </Routes>
      )}
      {!isAuthenticated && (
        <Routes>
          <Route
            path={paths.login}
            element={
              <LoginPage
                isLoading={isLoading}
                isError={isError}
                login={login}
              />
            }
          />
          <Route
            path={paths.createUser}
            element={<CreateUserPage login={login} />}
          />
          <Route
            path="/*"
            element={<Navigate to={paths.login} state={{ from: location }} />}
          />
        </Routes>
      )}
    </Box>
  )
}

export default AuthAwareRouter
