import { createTheme, SxProps, Theme } from '@mui/material'

interface Styles {
  [name: string]: SxProps<Theme>
}

export const createStyles = <T extends Styles>(styles: T): T => styles

export const colors = {
  brandBase: '#92CCCC',
  brandBaseLight: '#E2EAEA',
  dialog: '#092B30',
  brandAccent: '#FF6262',
  textBase: '#000000',
  textHeader: '#204053',
  white: '#ffffff',
}

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary']
  }
  interface PaletteOptions {
    neutral?: PaletteOptions['primary']
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true
  }
}

export const theme = createTheme({
  typography: {
    fontFamily: [
      'Roboto',
      'Nunito',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'sans-serif',
    ].join(','),
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    primary: {
      main: colors.brandBase,
    },
    secondary: {
      main: colors.brandAccent,
    },
    neutral: {
      main: colors.brandBaseLight,
    },
  },
  components: {
    // TODO: Maybe define custom ones that match UI style guide
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: 48,
          fontWeight: 700,
          color: colors.textHeader,
        },
        h2: {
          fontSize: 32,
          fontWeight: 800,
          color: colors.textHeader,
        },
        h3: {
          fontSize: 25,
          fontWeight: 400,
          fontFamily: 'Rubik',
          color: colors.textBase,
        },
        h4: {
          fontSize: 20,
          fontWeight: 400,
          color: colors.textBase,
        },
        h5: {
          fontSize: 18,
          fontWeight: 400,
          fontFamily: 'Rubik',
          color: colors.textBase,
        },
        body1: {
          fontSize: 16,
          fontWeight: 600,
          color: colors.textBase,
        },
        body2: {
          fontSize: 14,
          fontWeight: 500,
          color: colors.textBase,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        color: 'neutral',
        variant: 'contained',
        disableElevation: true,
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          // For transparent drawer edges around border
          backgroundColor: 'rgba(0,0,0,0)',
          color: 'rgba(0,0,0,0)',
        },
      },
    },
  },
})
