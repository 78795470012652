import { UseQueryResult } from 'react-query'
import { useApi } from '../ApiProvider'
import { Category, ErrorResponse } from '../../openapi/api/models'

export const useCategory = (
  categoryName: string
): UseQueryResult<Category, ErrorResponse> => {
  const { useCategoryQuery } = useApi()
  return useCategoryQuery(categoryName)
}
