import { FC, useEffect, useMemo } from 'react'
import { useParams } from 'react-router'
import { Container } from '@mui/system'
import { FormattedMessage, useIntl } from 'react-intl'
import { CircularProgress } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { styles } from './TermPage.style'
import { paths } from '../../paths'
// import { useFavorites } from '../../api/hooks/useFavoritesQuery'
import { useLogging } from '../../api/hooks/useLoggingMutation'

import Layout from '../layout/Layout'
import SentenceList from '../sentencelist/SentenceList'

import { Category } from '../../openapi/api'
import { ListItem } from '../listitem/ListItem'
import CategoryIcon from '../categoryicon/CategoryIcon'
import { useTerms } from '../../api/hooks/useTermsQuery'
import { useSubCategory } from '../../api/hooks/useSubCategoryQuery'
import { matchFavorites, termToAudioItemProps } from '../../helpers/audio-util'
import { useFavorites } from '../../api/hooks/useFavoritesQuery'

export interface TermPageProps {
  logout: () => void
}

/**
 * SentencePage provides options to navigate to various parts of the app.
 * @param props Properties for the component.
 * @returns React component.
 */
export const TermPage: FC<TermPageProps> = ({ logout }: TermPageProps) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { categoryName, subcategoryName } = useParams()
  const logger = useLogging()

  const {
    data: subcategory,
    isLoading: IsSubcategoryLoading,
    isError: IsSubcategoryError,
  } = useSubCategory(categoryName ?? '', subcategoryName ?? '') // TODO: enabled

  const {
    data: terms,
    isLoading: isTermsLoading,
    isError: isTermsError,
  } = useTerms(categoryName ?? '', subcategoryName ?? '')

  const {
    data: favorites,
    isLoading: isLoadingFavorites,
    isError: isErrorFavorites,
  } = useFavorites()

  useEffect(() => {
    logger.mutate({
      activityType: 'NavigateSubcategory',
      categoryName: `${categoryName}/${subcategoryName}`,
      additionalInfo: JSON.stringify({
        categoryName: categoryName,
        subcategoryName: subcategoryName,
      }),
    })
    // eslint-disable-next-line
  }, [])

  const termsWithFavorites = useMemo(() => {
    if (terms == null || favorites == null) {
      return []
    }
    return matchFavorites(terms, favorites.terms)
    // eslint-disable-next-line
  }, [terms, favorites])

  if (isTermsError || IsSubcategoryError || isErrorFavorites) {
    return <FormattedMessage id="general.error" />
  }

  if (isTermsLoading || IsSubcategoryLoading || isLoadingFavorites) {
    return <CircularProgress />
  }

  const termItems = termsWithFavorites?.map((term, index) => {
    const termProps = termToAudioItemProps(term)
    return <ListItem key={index} {...termProps} />
  })

  const navigateBack = (): void => {
    navigate(`${paths.categories}/${categoryName}${paths.subcategories}`)
  }

  return (
    <Layout
      title="general.appname"
      logout={logout}
      showLanguageModeTabs
      languageMode="terms"
    >
      <Container maxWidth="md" sx={styles.content}>
        <SentenceList
          icon={
            subcategory && subcategory.iconSrc.length > 0 ? (
              <CategoryIcon
                category={subcategory as Category}
                height={62}
                width={62}
              />
            ) : undefined
          }
          navigateBack={navigateBack}
          title={subcategoryName ?? ''}
          overline={categoryName}
          description={intl.formatMessage({ id: 'sentences.instruction' })}
        >
          {termItems}
        </SentenceList>
      </Container>
    </Layout>
  )
}

export default TermPage
