/* tslint:disable */
/* eslint-disable */
/**
 * Migsosu BFF
 * Backend for frontend API for Migsosu app.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Sentence
 */
export interface Sentence {
    /**
     * 
     * @type {string}
     * @memberof Sentence
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof Sentence
     */
    category: string;
    /**
     * 
     * @type {string}
     * @memberof Sentence
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Sentence
     */
    transcript: string;
    /**
     * 
     * @type {string}
     * @memberof Sentence
     */
    audioUrl: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof Sentence
     */
    timestamps?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof Sentence
     */
    isFavorite?: boolean;
}

/**
 * Check if a given object implements the Sentence interface.
 */
export function instanceOfSentence(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uuid" in value;
    isInstance = isInstance && "category" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "transcript" in value;
    isInstance = isInstance && "audioUrl" in value;

    return isInstance;
}

export function SentenceFromJSON(json: any): Sentence {
    return SentenceFromJSONTyped(json, false);
}

export function SentenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Sentence {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'category': json['category'],
        'title': json['title'],
        'transcript': json['transcript'],
        'audioUrl': json['audioUrl'],
        'timestamps': !exists(json, 'timestamps') ? undefined : json['timestamps'],
        'isFavorite': !exists(json, 'isFavorite') ? undefined : json['isFavorite'],
    };
}

export function SentenceToJSON(value?: Sentence | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uuid': value.uuid,
        'category': value.category,
        'title': value.title,
        'transcript': value.transcript,
        'audioUrl': value.audioUrl,
        'timestamps': value.timestamps,
        'isFavorite': value.isFavorite,
    };
}

