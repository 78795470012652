import React, { FC, useState } from 'react'
import { Box, IconProps, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import { styles } from './SentenceList.style'
import { AudioItemProps } from '../listitem/ListItem'

export interface SentenceListProps {
  title: string
  overline?: string
  description: string
  navigateBack: () => void
  icon?: React.ReactElement<IconProps>
  children?: Array<React.ReactElement<AudioItemProps> | JSX.Element>
}

/**
 * SentenceItem a title and an icon and a link to the category content.
 * @param props Properties for the component.
 * @returns React component.
 */
export const SentenceList: FC<SentenceListProps> = ({
  navigateBack,
  icon,
  title,
  overline,
  description,
  children,
}: SentenceListProps) => {
  const detailsCount = children?.length ?? 0
  const [detailsOpen, setDetailsOpen] = useState<boolean[]>(
    new Array(detailsCount).fill(false)
  )
  const openDetails = (index: number): ((open: boolean) => void) => {
    // Convenience function to open/close details at specific index
    return open => {
      const newDetails = new Array(detailsCount).fill(false) // Only one drawer is open at a time
      newDetails[index] = open
      setDetailsOpen(newDetails)
    }
  }

  // Assign openDetails and isDetailsOpen handlers to children
  const sentenceItems = children?.map((sentence, index) => {
    if (!('content' in sentence.props)) {
      // Do not process if not an audio item element (e.g. a divider element)
      return sentence
    }
    const sentenceClone = React.cloneElement(sentence, {
      ...sentence.props,
      openDetails: openDetails(index),
      isDetailsOpen: detailsOpen[index],
    })
    return sentenceClone
  })

  const handleBackClick = (): void => {
    navigateBack()
  }

  return (
    <Box sx={styles.root}>
      <Box sx={styles.header}>
        {icon !== undefined ? (
          React.cloneElement(icon, { ...icon.props, sx: styles.icon })
        ) : (
          <></>
        )}
        <Box>
          {overline && (
            <Typography component="h2" variant="body1" sx={styles.overline}>
              {overline}
            </Typography>
          )}
          <Typography component="h1" variant="h2">
            {title}
          </Typography>
          <Typography component="p" variant="body2">
            {description}
          </Typography>
        </Box>
        <ArrowBackIcon sx={styles.backIcon} onClick={handleBackClick} />
      </Box>
      <Box sx={styles.listContainer}>{sentenceItems}</Box>
    </Box>
  )
}

export default SentenceList
