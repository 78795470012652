import { createStyles, colors, theme } from '../../theme'

export const styles = createStyles({
  bodyAtlasContainer: {
    position: 'relative',
    width: '100%',
    borderRadius: '34px 0 0 0',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    height: 'calc(80vh - 120px)',
  },
  infoText: {
    textAlign: 'center',
    paddingTop: '32px',
  },
  infoBox: {
    color: theme.palette.common.white,
  },
  detailsContainer: {
    height: '200px',
    backgroundColor: colors.dialog,
    borderRadius: '34px 0px 0px 0px',
  },
  drawerExpandButton: {
    color: theme.palette.common.white,
    fontSize: 48,
    marginRight: 'auto',
  },
  content: {
    padding: 0,
  },
  unityCanvas: {
    borderRadius: '34px 0 0 0',
    width: '100%',
    height: '100%',
  },
  inputBox: {
    display: 'float',
    width: '320px',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
  },
  textInputField: {
    width: '70px',
    height: '20px',
    marginRight: '10px',
  },
  button: {
    marginRight: '10px',
  },
  loadingContainerTable: {
    width: '100%',
    height: '100%',
    display: 'table',
  },
  loadingContainerCell: {
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    height: '20vh',
    paddingX: 2,
    paddingTop: '12px',
  },
  genderButtonContainer: {
    'button:first-of-type': {
      marginRight: '10px',
    },
    '& .MuiButton-root.Mui-disabled': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiSvgIcon-root': {
        color: 'white',
      },
    },
    display: 'flex',
    backgroundColor: '#E2EAEA',
    borderRadius: '42px',
    alignItems: 'center',
    padding: '6px',
    marginLeft: 4,
  },
  circleButton: {
    minWidth: 48,
    width: 48,
    height: 48,
    borderRadius: '48px',
  },
  circleButtonSmall: {
    minWidth: 42,
    width: 42,
    height: 42,
    borderRadius: '42px',
  },
  infoButton: {
    marginLeft: 'auto',
  },
  searchButton: {
    marginX: 'auto',
  },
  searchAutocomplete: {
    width: '100vw',
  },
  infoOverlay: {
    // Overlay Unity player
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: '34px 0 0 0',
    // Use same colors as dialog
    backgroundColor: colors.dialog,
    color: theme.palette.common.white,
    // Display content as vertical list
    display: 'flex',
    flexDirection: 'column',
  },
  infoIcon: {
    height: 'auto',
    marginX: 'auto',
    marginBottom: 2,
  },
  infoRotateIcon: {
    width: '15%',
  },
  infoZoomIcon: {
    width: '20%',
  },
  infoResetIcon: {
    width: '20%',
    position: 'relative',
  },
  resetIconBackground: {
    width: '100%',
    height: '100%',
  },
  resetIcon: {
    color: colors.dialog,
    position: 'absolute',
    width: '50%',
    height: 'auto',
    left: '25%',
    top: '25%',
  },
  infoRow: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& p': {
      color: 'white',
    },
  },
  closeIcon: {
    color: theme.palette.common.white,
  },
  searchTextField: {
    backgroundColor: colors.white,
    border: `1px solid ${theme.palette.grey[600]}`,
    borderRadius: '8px',
    '& .MuiFilledInput-root': {
      borderRadius: '8px',
    },
  },
  partSearchButton: {
    width: '100%',
    backgroundColor: 'white',
    marginY: '5px',
  },
})

export const unityStyle = {
  width: '100%',
  height: '100%',
  borderRadius: '34px 0 0 0',
}
