import { SvgIcon, SxProps, Theme } from '@mui/material'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { styles } from './IconComponent.style'
import { ReactComponent as FavoriteSvg } from '../../assets/icons/favorite.svg'
import { ReactComponent as ProfileSvg } from '../../assets/icons/profile.svg'

export interface IconComponentProps {
  name: 'Profile' | 'Favorite' // Enforce only known svg icons. Consider refactoring this
  sx?: SxProps<Theme>
}

export const IconComponent: FC<IconComponentProps> = ({
  name,
  sx,
}: IconComponentProps) => {
  // TODO: Change naming s.t. passing args through translations are not needed
  const intl = useIntl()
  const Icons = {
    Favorite: intl.formatMessage({ id: 'icons.favorite' }),
    Profile: intl.formatMessage({ id: 'icons.profile' }),
  }

  let icon: any | null = null
  switch (name) {
    case Icons.Favorite:
      icon = FavoriteSvg
      break
    case Icons.Profile:
      icon = ProfileSvg
      break
  }

  return (
    (
      <SvgIcon
        component={icon}
        inheritViewBox
        sx={[...(Array.isArray(sx) ? sx : [sx]), styles.icon]}
      />
    ) ?? <></>
  )
}

export default IconComponent
