export const isValidInput = (
  passwd1: string,
  passwd2: string,
  username: string
): { isValid: boolean; errorId: string } => {
  if (passwd1 !== passwd2) {
    return { isValid: false, errorId: 'createUser.passwordsMatchError' }
  }
  if (passwd1.length < 6) {
    return { isValid: false, errorId: 'createUser.passwordTooShort' }
  }
  const lastDotIndex = username.lastIndexOf('.')
  const ampersatIndex = username.indexOf('@')
  if (ampersatIndex <= 0 || lastDotIndex <= 3 || lastDotIndex < ampersatIndex) {
    return { isValid: false, errorId: 'createUser.invalidEmailFormat' }
  }
  return { isValid: true, errorId: '' }
}

export const cleanEmail = (s: string): string => {
  return s.toLowerCase().trim()
}
