import { FC } from 'react'

import { Box, Typography } from '@mui/material'
import { useIntl } from 'react-intl'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import ListEmptyIcon from '@mui/icons-material/PlaylistRemoveOutlined'

import { styles } from './CategoryList.style'

export interface CategoryListProps {
  title: string
  description: string
  children?: JSX.Element | JSX.Element[]
  navigateBack: () => void
  languageMode: 'terms' | 'sentences'
}

/**
 * CategoryItem a title and an icon and a link to the category content.
 * @param props Properties for the component.
 * @returns React component.
 */
export const CategoryList: FC<CategoryListProps> = ({
  title,
  description,
  children,
  navigateBack,
  languageMode,
}: CategoryListProps) => {
  const intl = useIntl()

  const handleBackClick = (): void => {
    navigateBack()
  }

  const isEmpty = children === undefined || (children as []).length === 0

  const languageModeText =
    languageMode === 'terms'
      ? intl.formatMessage({ id: 'general.termsHelp' })
      : intl.formatMessage({ id: 'general.languagehelp' })

  return (
    <Box sx={styles.root}>
      <Box sx={styles.header}>
        <Box>
          <Typography component="h1" variant="h2">
            {title}
          </Typography>
          <Typography component="p" variant="body2">
            {description}
          </Typography>
        </Box>
        <ArrowBackIcon sx={styles.backIcon} onClick={handleBackClick} />
      </Box>
      <Box sx={styles.listContainer}>
        {isEmpty ? (
          <Box sx={styles.infoMessageContainer}>
            <InfoOutlinedIcon sx={styles.infoIcon} />
            <ListEmptyIcon sx={styles.emptyListIcon} />
            <Typography sx={styles.infoMessage}>
              {intl.formatMessage(
                { id: 'categories.empty' },
                { languageMode: languageModeText }
              )}
            </Typography>
          </Box>
        ) : (
          children
        )}
      </Box>
    </Box>
  )
}

export default CategoryList
