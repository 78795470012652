import { UseQueryResult } from 'react-query'
import { useApi } from '../ApiProvider'
import { ErrorResponse, Term } from '../../openapi/api/models'

export const useTerms = (
  categoryName: string,
  subCategoryName: string
): UseQueryResult<Term[], ErrorResponse> => {
  const { useTermsQuery } = useApi()
  return useTermsQuery(categoryName, subCategoryName)
}
