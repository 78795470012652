/* tslint:disable */
/* eslint-disable */
/**
 * Migsosu BFF
 * Backend for frontend API for Migsosu app.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Sentence } from './Sentence';
import {
    SentenceFromJSON,
    SentenceFromJSONTyped,
    SentenceToJSON,
} from './Sentence';
import type { Term } from './Term';
import {
    TermFromJSON,
    TermFromJSONTyped,
    TermToJSON,
} from './Term';

/**
 * 
 * @export
 * @interface GetFavorites200Response
 */
export interface GetFavorites200Response {
    /**
     * 
     * @type {Array<Sentence>}
     * @memberof GetFavorites200Response
     */
    sentences: Array<Sentence>;
    /**
     * 
     * @type {Array<Term>}
     * @memberof GetFavorites200Response
     */
    terms: Array<Term>;
}

/**
 * Check if a given object implements the GetFavorites200Response interface.
 */
export function instanceOfGetFavorites200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sentences" in value;
    isInstance = isInstance && "terms" in value;

    return isInstance;
}

export function GetFavorites200ResponseFromJSON(json: any): GetFavorites200Response {
    return GetFavorites200ResponseFromJSONTyped(json, false);
}

export function GetFavorites200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetFavorites200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sentences': ((json['sentences'] as Array<any>).map(SentenceFromJSON)),
        'terms': ((json['terms'] as Array<any>).map(TermFromJSON)),
    };
}

export function GetFavorites200ResponseToJSON(value?: GetFavorites200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sentences': ((value.sentences as Array<any>).map(SentenceToJSON)),
        'terms': ((value.terms as Array<any>).map(TermToJSON)),
    };
}

