import { FC, useEffect, useMemo } from 'react'
import { useParams } from 'react-router'
import { Container } from '@mui/system'
import { FormattedMessage, useIntl } from 'react-intl'
import { CircularProgress } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { styles } from './SentencePage.style'
import { paths } from '../../paths'
import { useSentences } from '../../api/hooks/useSentencesQuery'
import { useFavorites } from '../../api/hooks/useFavoritesQuery'
import { useLogging } from '../../api/hooks/useLoggingMutation'

import Layout from '../layout/Layout'
import SentenceList from '../sentencelist/SentenceList'

import { ListItem } from '../listitem/ListItem'
import CategoryIcon from '../categoryicon/CategoryIcon'
import { useCategory } from '../../api/hooks/useCategory'
import {
  matchFavorites,
  sentenceToAudioItemProps,
} from '../../helpers/audio-util'

export interface SentencePageProps {
  logout: () => void
}

/**
 * SentencePage provides options to navigate to various parts of the app.
 * @param props Properties for the component.
 * @returns React component.
 */
export const SentencePage: FC<SentencePageProps> = ({
  logout,
}: SentencePageProps) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { categoryName } = useParams()
  const logger = useLogging()

  const {
    data: category,
    isLoading: isLoadingcategory,
    isError: isErrorcategory,
  } = useCategory(categoryName ?? '')
  const {
    data: sentences,
    isLoading: isLoadingSentences,
    isError: isErrorSentences,
  } = useSentences(categoryName ?? '')
  const {
    data: favorites,
    isLoading: isLoadingFavorites,
    isError: isErrorFavorites,
  } = useFavorites()

  // Not sure if this is abuse of 'useEffect'
  useEffect(() => {
    logger.mutate({
      activityType: 'NavigateCategory',
      categoryName: categoryName,
      additionalInfo: JSON.stringify({
        mode: 'sentences',
      }),
    })
    // eslint-disable-next-line
  }, [])

  const sentencesWithFavorites = useMemo(() => {
    if (sentences == null || favorites == null) {
      return []
    }
    return matchFavorites(sentences, favorites.sentences)
    // eslint-disable-next-line
  }, [sentences, favorites])

  if (isErrorSentences || isErrorFavorites || isErrorcategory) {
    return <FormattedMessage id="general.error" />
  }

  if (isLoadingSentences || isLoadingFavorites || isLoadingcategory) {
    return <CircularProgress />
  }

  if (
    sentences === undefined ||
    favorites === undefined ||
    category === undefined
  ) {
    return <FormattedMessage id="general.error" />
  }

  const sentenceItems = sentencesWithFavorites.map((sentence, index) => {
    const sentenceProps = sentenceToAudioItemProps(sentence)
    return <ListItem key={index} {...sentenceProps} />
  })

  const navigateBack = (): void => {
    navigate(`${paths.categories}/sentences`)
  }

  return (
    <Layout
      title="general.appname"
      logout={logout}
      showLanguageModeTabs
      languageMode="sentences"
    >
      <Container maxWidth="md" sx={styles.content}>
        <SentenceList
          icon={<CategoryIcon category={category} height={62} width={62} />}
          navigateBack={navigateBack}
          title={categoryName ?? ''}
          description={intl.formatMessage({ id: 'sentences.instruction' })}
        >
          {sentenceItems}
        </SentenceList>
      </Container>
    </Layout>
  )
}

export default SentencePage
