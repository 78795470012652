import { FC } from 'react'
import { CircularProgress, Container } from '@mui/material'

import { styles } from './CategoryPage.style'
import { FormattedMessage, useIntl } from 'react-intl'

import Layout from '../layout/Layout'
import CategoryList from '../categorylist/CategoryList'
import { CategoryItem, CategoryType } from '../categoryitem/CategoryItem'
import { useCategories } from '../../api/hooks/useCategoriesQuery'
import { useNavigate, useParams } from 'react-router'
import { paths } from '../../paths'

export interface CategoryPageProps {
  logout: () => void
}

/**
 * CategoryPage provides options to navigate to various parts of the app.
 * @param props Properties for the component.
 * @returns React component.
 */
export const CategoryPage: FC<CategoryPageProps> = ({
  logout,
}: CategoryPageProps) => {
  const { type } = useParams() // Not ideal
  const categoryType = type as CategoryType
  const intl = useIntl()
  const navigate = useNavigate()
  const { data, isLoading, isError } = useCategories()
  const categoryItems = data?.map(category => {
    return (
      <CategoryItem
        key={category.uuid}
        category={category}
        type={categoryType}
      />
    )
  })

  if (isError) {
    return <FormattedMessage id="general.error" />
  }

  if (isLoading) {
    return <CircularProgress />
  }

  const languageMode = type && type === 'subcategories' ? 'terms' : 'sentences'

  return (
    <Layout
      title="general.appname"
      logout={logout}
      showLanguageModeTabs
      languageMode={languageMode}
    >
      <Container maxWidth="md" sx={styles.content}>
        <CategoryList
          title={intl.formatMessage({ id: 'categories.title' })}
          description={intl.formatMessage({ id: 'categories.description' })}
          navigateBack={() => {
            navigate(paths.categories)
          }}
          languageMode={languageMode}
        >
          {categoryItems}
        </CategoryList>
      </Container>
    </Layout>
  )
}

export default CategoryPage
