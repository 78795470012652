import { createStyles } from '../../theme'

export const styles = createStyles({
  dialog: {},
  dialogContent: {
    gap: 2,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 3,
    paddingX: 3,
  },
  dialogActions: {
    paddingX: 3,
    paddingBottom: 3,
    display: 'flex',
    '& button:first-of-type': {
      marginRight: 'auto',
    },
  },
  dialogButton: {
    width: '30%',
  },
  rating: {
    marginX: 'auto',
    display: 'flex',
    '& .MuiRating-label': {
      // Hide label to space stars evenly
      visibility: 'hidden',
    },
  },
  headerBox: {
    display: 'flex',
  },
  ratingIcon: {
    fontSize: '42px',
    paddingX: '5px',
  },
  closeButton: {
    position: 'relative',
    top: '-8px',
    right: '-8px',
  },
  dialogText: {
    wordWrap: 'normal',
    marginRight: 4,
  },
})
