/* tslint:disable */
/* eslint-disable */
/**
 * Migsosu BFF
 * Backend for frontend API for Migsosu app.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserActivityLog
 */
export interface UserActivityLog {
    /**
     * 
     * @type {string}
     * @memberof UserActivityLog
     */
    activityType: string;
    /**
     * 
     * @type {string}
     * @memberof UserActivityLog
     */
    categoryName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserActivityLog
     */
    sentenceID?: string;
    /**
     * 
     * @type {string}
     * @memberof UserActivityLog
     */
    additionalInfo?: string;
}

/**
 * Check if a given object implements the UserActivityLog interface.
 */
export function instanceOfUserActivityLog(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "activityType" in value;

    return isInstance;
}

export function UserActivityLogFromJSON(json: any): UserActivityLog {
    return UserActivityLogFromJSONTyped(json, false);
}

export function UserActivityLogFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserActivityLog {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activityType': json['activityType'],
        'categoryName': !exists(json, 'categoryName') ? undefined : json['categoryName'],
        'sentenceID': !exists(json, 'sentenceID') ? undefined : json['sentenceID'],
        'additionalInfo': !exists(json, 'additionalInfo') ? undefined : json['additionalInfo'],
    };
}

export function UserActivityLogToJSON(value?: UserActivityLog | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activityType': value.activityType,
        'categoryName': value.categoryName,
        'sentenceID': value.sentenceID,
        'additionalInfo': value.additionalInfo,
    };
}

