import { UseQueryResult } from 'react-query'
import { useApi } from '../ApiProvider'
import { Sentence, ErrorResponse } from '../../openapi/api/models'

export const useSentences = (
  categoryName: string
): UseQueryResult<Sentence[], ErrorResponse> => {
  const { useSentenceQuery } = useApi()
  return useSentenceQuery(categoryName)
}
