import { UseQueryResult } from 'react-query'
import { TermWithCategory, useApi } from '../ApiProvider'
import { ErrorResponse } from '../../openapi/api/models'

export const useAllTerms = (): UseQueryResult<
  TermWithCategory[],
  ErrorResponse
> => {
  const { useAllTermsQuery } = useApi()
  return useAllTermsQuery()
}
