/* tslint:disable */
/* eslint-disable */
/**
 * Migsosu BFF
 * Backend for frontend API for Migsosu app.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BodyPart
 */
export interface BodyPart {
    /**
     * 
     * @type {string}
     * @memberof BodyPart
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof BodyPart
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof BodyPart
     */
    description: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof BodyPart
     */
    optimalViewDirection: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof BodyPart
     */
    optimalViewDist: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof BodyPart
     */
    malePosition?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof BodyPart
     */
    femalePosition?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof BodyPart
     */
    alwaysVisible: boolean;
    /**
     * 
     * @type {string}
     * @memberof BodyPart
     */
    audioUrl: string;
    /**
     * 
     * @type {Array<BodyPart>}
     * @memberof BodyPart
     */
    children: Array<BodyPart>;
}

/**
 * Check if a given object implements the BodyPart interface.
 */
export function instanceOfBodyPart(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uuid" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "optimalViewDirection" in value;
    isInstance = isInstance && "optimalViewDist" in value;
    isInstance = isInstance && "alwaysVisible" in value;
    isInstance = isInstance && "audioUrl" in value;
    isInstance = isInstance && "children" in value;

    return isInstance;
}

export function BodyPartFromJSON(json: any): BodyPart {
    return BodyPartFromJSONTyped(json, false);
}

export function BodyPartFromJSONTyped(json: any, ignoreDiscriminator: boolean): BodyPart {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'title': json['title'],
        'description': json['description'],
        'optimalViewDirection': json['optimalViewDirection'],
        'optimalViewDist': json['optimalViewDist'],
        'malePosition': !exists(json, 'malePosition') ? undefined : json['malePosition'],
        'femalePosition': !exists(json, 'femalePosition') ? undefined : json['femalePosition'],
        'alwaysVisible': json['alwaysVisible'],
        'audioUrl': json['audioUrl'],
        'children': ((json['children'] as Array<any>).map(BodyPartFromJSON)),
    };
}

export function BodyPartToJSON(value?: BodyPart | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uuid': value.uuid,
        'title': value.title,
        'description': value.description,
        'optimalViewDirection': value.optimalViewDirection,
        'optimalViewDist': value.optimalViewDist,
        'malePosition': value.malePosition,
        'femalePosition': value.femalePosition,
        'alwaysVisible': value.alwaysVisible,
        'audioUrl': value.audioUrl,
        'children': ((value.children as Array<any>).map(BodyPartToJSON)),
    };
}

