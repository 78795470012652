import { UseQueryResult } from 'react-query'
import { useApi } from '../ApiProvider'
import { ErrorResponse, SubCategory } from '../../openapi/api/models'

export const useSubCategories = (
  categoryName: string
): UseQueryResult<SubCategory[], ErrorResponse> => {
  const { useSubCategoriesQuery } = useApi()
  return useSubCategoriesQuery(categoryName)
}
