import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  Popper,
  TextField,
  Typography,
} from '@mui/material'
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import Layout from '../layout/Layout'
import { styles, unityStyle } from './BodyAtlasPage.style'
import { Unity, useUnityContext } from 'react-unity-webgl'
import { useBodyParts } from '../../api/hooks/useBodyPartsQuery'

import AccessibilityIcon from '@mui/icons-material/Accessibility'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import FemaleIcon from '@mui/icons-material/Female'
import MaleIcon from '@mui/icons-material/Male'
import { BodyPart } from '../../openapi/api'
import BodyPartDrawer from '../bodypartdrawer/BodyPartDrawer'
import { useLocation } from 'react-router'
import HandRotateIconUrl from '../../assets/icons/hand_rotate.svg'
import HandZoomIconUrl from '../../assets/icons/hand_zoom.svg'
import { Circle } from '@mui/icons-material'
import { FormattedMessage } from 'react-intl'

export interface BodyAtlasPageProps {
  logout: () => void
}

interface BodyPartOption {
  uuid: string
  name: string
}

type gender = 'male' | 'female'

const flattenBodyParts = (bodyParts: BodyPart[]): BodyPart[] => {
  const result: BodyPart[] = []
  const add = (bodyPart: BodyPart): void => {
    result.push(bodyPart)
    for (let i = 0; i < bodyPart.children.length; i++) {
      add(bodyPart.children[i])
    }
  }
  for (let i = 0; i < bodyParts.length; i++) {
    add(bodyParts[i]) // Add top level to map and recurse...
  }
  return result
}

/**
 * BodyAtlasPage provides a 3D model of a human body to interact with.
 * @param props Properties for the component.
 * @returns React component.
 */
export const BodyAtlasPage: FC<BodyAtlasPageProps> = ({
  logout,
}: BodyAtlasPageProps) => {
  const {
    unityProvider,
    isLoaded: isUnityLoaded,
    loadingProgression,
    UNSAFE__detachAndUnloadImmediate: detachAdnUnloadImmediate,
    sendMessage,
    addEventListener,
    removeEventListener,
    initialisationError,
    unload,
  } = useUnityContext({
    loaderUrl: 'unitybuild/Build/unitybuild.loader.js',
    dataUrl: 'unitybuild/Build/unitybuild.data.gz',
    frameworkUrl: 'unitybuild/Build/unitybuild.framework.js.gz',
    codeUrl: 'unitybuild/Build/unitybuild.wasm.gz',
  })

  const setFreeLook = useCallback(async () => {
    sendMessage('ReactReceiver', 'SetFreeLook')
  }, [sendMessage])

  const resetCamera = useCallback(async () => {
    sendMessage('ReactReceiver', 'ResetCamera')
  }, [sendMessage])

  const [gender, setGender] = useState<gender>('male')
  const { data: bodyPartsNested } = useBodyParts()
  const [openDrawers, setOpenDrawers] = useState<boolean[]>([])
  const searchAnchor = useRef(null)
  const [searchOpen, setSearchOpen] = useState(false)
  const location = useLocation()
  const [infoOpen, setInfoOpen] = useState(false)

  // Flatten body parts nested struct and setup array to manage opening of drawers
  const bodyParts = useMemo(() => {
    if (bodyPartsNested !== undefined) {
      const bodyPartsFlat = flattenBodyParts(bodyPartsNested)
      const openDrawersList = new Array(bodyPartsFlat.length).fill(false) // All drawers are close by default
      setOpenDrawers(openDrawersList)
      return bodyPartsFlat
    }
  }, [bodyPartsNested])

  // Function for focussing a body part
  const focusPart = useCallback(
    (pointId: string): void => {
      setOpenDrawers(currentOpenDrawers => {
        // Open drawer which matches specific id
        const index = bodyParts?.map(b => b.uuid).indexOf(pointId)
        if (index !== undefined) {
          const updatedOpenDrawers = new Array(currentOpenDrawers.length).fill(
            false
          )
          updatedOpenDrawers[index] = true
          return updatedOpenDrawers
        }
        return currentOpenDrawers
      })
    },
    [bodyParts]
  )

  // Create body part drawer component for each body part
  const bodyPartDrawers = useMemo(() => {
    if (bodyParts !== undefined) {
      const bodyPartDrawers: JSX.Element[] = []
      for (let i = 0; i < bodyParts.length; i++) {
        const bodyPart = bodyParts[i]
        const bodyPartDrawer = (
          <BodyPartDrawer
            key={bodyPart.uuid}
            bodyPart={bodyPart}
            isOpen={openDrawers[i]}
            close={() => {
              setOpenDrawers(old => {
                return new Array(old.length).fill(false)
              })
              void setFreeLook()
            }}
          />
        )
        bodyPartDrawers.push(bodyPartDrawer)
      }
      return bodyPartDrawers
    }
  }, [bodyParts, openDrawers, setFreeLook])

  const setUnityPointFocus = (pointID: string): void => {
    sendMessage('ReactReceiver', 'SetFocus', pointID)
  }

  const setUnitySex = (isMale: boolean): void => {
    sendMessage('ReactReceiver', 'SetSex', JSON.stringify(isMale))
  }

  // Callback for unity to set bodypart point id
  const setPointFocusFromUnity = useCallback(
    (pointId: string) => {
      focusPart(pointId)
    },
    [focusPart]
  )

  useEffect(() => {
    if (isUnityLoaded && bodyPartsNested !== undefined) {
      const dataPointsJson = JSON.stringify(bodyPartsNested)
      sendMessage('ReactReceiver', 'LoadPoints', dataPointsJson)
      console.log('data_Json:', dataPointsJson)
    }
  }, [isUnityLoaded, bodyPartsNested, sendMessage])

  useEffect(() => {
    // Documentation for unity -> react communication
    // https://react-unity-webgl.dev/docs/api/event-system
    // https://docs.unity3d.com/Manual/webgl-interactingwithbrowserscripting.html
    addEventListener('PointFocus', setPointFocusFromUnity)
    return () => {
      removeEventListener('PointFocus', setPointFocusFromUnity)
    }
  }, [addEventListener, removeEventListener, setPointFocusFromUnity])

  // Effect to unload unity on browser navigation (e.g. history pop)
  useEffect(() => {
    if (isUnityLoaded) {
      return () => {
        void detachAdnUnloadImmediate()
      }
    }
  }, [location, isUnityLoaded, detachAdnUnloadImmediate])

  const switchGender = (gender: gender): void => {
    setGender(gender)
    setUnitySex(gender === 'male')
  }

  const toggleInfoOverlay = (): void => {
    setInfoOpen(current => !current)
  }

  // Array of body part options with no duplicates
  const bodyPartOptions = useMemo(() => {
    const parts = Array.from(
      new Map(
        bodyParts?.map(part => [
          part.title, // Only used to ensure there are no duplicates
          { name: part.title, uuid: part.uuid },
        ]) ?? []
      )
    )
    return Array.from(parts.values()).map(value => value[1])
  }, [bodyParts])

  return (
    <Layout
      title="general.appname"
      isBodyAtlas
      logout={logout}
      beforeNavigate={unload}
    >
      <Container maxWidth="md" sx={styles.content}>
        {!isUnityLoaded && (
          <Typography sx={styles.infoText}>Loading...</Typography>
        )}
        {initialisationError !== null && (
          <Typography sx={styles.infoText}>
            {initialisationError?.name}
          </Typography>
        )}
        <Box sx={styles.bodyAtlasContainer}>
          <>
            {!isUnityLoaded && (
              <Box sx={styles.loadingContainerTable}>
                <Box sx={styles.loadingContainerCell}>
                  <CircularProgress
                    size="5rem"
                    variant="determinate"
                    value={Math.round(loadingProgression * 100)}
                  />
                </Box>
              </Box>
            )}
            <Unity
              unityProvider={unityProvider}
              style={{
                ...unityStyle,
                visibility: isUnityLoaded ? 'visible' : 'hidden',
              }}
            />
            {bodyPartDrawers}
            <Box
              sx={{
                ...styles.infoOverlay,
                visibility: infoOpen ? 'visible' : 'hidden',
              }}
            >
              <Box sx={styles.infoRow}>
                <Box
                  component="img"
                  src={HandRotateIconUrl}
                  sx={[styles.infoIcon, styles.infoRotateIcon]}
                />
                <Typography>
                  <Typography component="span" color="primary">
                    <FormattedMessage id="bodyAtlas.infoRotate1" /> &nbsp;
                  </Typography>
                  <FormattedMessage id="bodyAtlas.infoRotate2" />
                </Typography>
              </Box>
              <Box sx={styles.infoRow}>
                <Box
                  component="img"
                  src={HandZoomIconUrl}
                  sx={[styles.infoIcon, styles.infoZoomIcon]}
                />
                <Typography>
                  <Typography component="span" color="primary">
                    <FormattedMessage id="bodyAtlas.infoZoom1" /> &nbsp;
                  </Typography>
                  <FormattedMessage id="bodyAtlas.infoZoom2" />
                </Typography>
              </Box>
              <Box sx={styles.infoRow}>
                <Box sx={[styles.infoIcon, styles.infoResetIcon]}>
                  <Circle sx={styles.resetIconBackground} />
                  <AccessibilityIcon sx={styles.resetIcon} />
                </Box>
                <Typography>
                  <Typography component="span" color="primary">
                    <FormattedMessage id="bodyAtlas.infoReset1" /> &nbsp;
                  </Typography>
                  <FormattedMessage id="bodyAtlas.infoReset2" />
                </Typography>
              </Box>
            </Box>
          </>
        </Box>
        <Box ref={searchAnchor} sx={styles.buttonContainer}>
          <Button sx={styles.circleButton} onClick={resetCamera}>
            <AccessibilityIcon />
          </Button>
          <Box sx={styles.genderButtonContainer}>
            <Button
              sx={styles.circleButtonSmall}
              disabled={gender === 'male'}
              onClick={() => switchGender('male')}
            >
              <MaleIcon />
            </Button>
            <Button
              sx={styles.circleButtonSmall}
              disabled={gender === 'female'}
              onClick={() => switchGender('female')}
            >
              <FemaleIcon />
            </Button>
          </Box>
          <Button
            onClick={() => setSearchOpen(open => !open)}
            sx={[styles.circleButton, styles.searchButton]}
          >
            <SearchIcon />
          </Button>
          <Button
            sx={[styles.circleButton, styles.infoButton]}
            onClick={toggleInfoOverlay}
            color={infoOpen ? 'primary' : undefined}
          >
            {infoOpen ? (
              <CloseIcon sx={styles.closeIcon} />
            ) : (
              <InfoOutlinedIcon />
            )}
          </Button>
          <Popper // Popup with body part search field
            onBlur={() => setSearchOpen(false)}
            open={searchOpen}
            placement="bottom"
          >
            <Autocomplete // Body part searcher
              disablePortal
              open
              popupIcon={<></>}
              onBlur={() => setSearchOpen(false)}
              getOptionLabel={option => option.name}
              options={bodyPartOptions}
              renderOption={(_, option: BodyPartOption) => (
                <Button
                  key={option.uuid}
                  variant="contained"
                  sx={styles.partSearchButton}
                  onClick={() => {
                    setSearchOpen(false)
                    focusPart(option.uuid)
                    setUnityPointFocus(option.uuid)
                  }}
                >
                  {option.name}
                </Button>
              )}
              sx={styles.searchAutocomplete}
              PopperComponent={params => (
                <Popper {...params} placement="bottom" />
              )}
              renderInput={params => (
                <TextField
                  autoFocus
                  variant="filled"
                  {...params}
                  sx={styles.searchTextField}
                  InputProps={{ ...params.InputProps, disableUnderline: true }}
                  label="Kropsdel"
                />
              )}
            />
          </Popper>
        </Box>
      </Container>
    </Layout>
  )
}
