import { UseQueryResult } from 'react-query'
import { useApi } from '../ApiProvider'
import { Sentence, ErrorResponse } from '../../openapi/api/models'

export const useAllSentences = (): UseQueryResult<
  Sentence[],
  ErrorResponse
> => {
  const { useAllSentencesQuery } = useApi()
  return useAllSentencesQuery()
}
